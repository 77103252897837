import { useEffect, useState } from 'react'
import { Form, message } from 'antd'
import * as _ from 'lodash'

import { commonHelper } from 'helpers'
import { commonUtils, eventManager } from 'utils'

import { useGetFamilyInfo, useGetBeneficiaries, useGetDependents, useGetRecipients } from 'services/clients/clients.service'

import useGeneral from './useGeneral'
import { addEditFormMutation, deleteBeneficiariesMutation, deleteDependentsMutation, deleteRecipientsMutation } from 'gql/client'
import { useCustomMutation } from 'services/shared/mutation'
import { DeleteWithIdNumberAnd_IdsPayload, TAddEditFormMutationPayload } from 'services/shared/mutation.payload'
import { FORMS_ENUM } from 'enum/form.enum'

const useFamily = () => {
  const [form] = Form.useForm()
  const deleteBeneficiaries = useCustomMutation<DeleteWithIdNumberAnd_IdsPayload>({ mutation: deleteBeneficiariesMutation })
  const deleteDependents = useCustomMutation<DeleteWithIdNumberAnd_IdsPayload>({ mutation: deleteDependentsMutation })
  const deleteRecipients = useCustomMutation<DeleteWithIdNumberAnd_IdsPayload>({ mutation: deleteRecipientsMutation })
  const addEditFamily = useCustomMutation<TAddEditFormMutationPayload>({ mutation: addEditFormMutation, refetchQueries: ['getClientFormsProgress'] })
  const urlParams = new URLSearchParams(window.location.search)
  const idNumber = Number(urlParams.get('idNumber'))
  const { data, loading: formInitialDataLoading, refetch } = useGetFamilyInfo(idNumber)
  const { beneficiaries } = useGetBeneficiaries(idNumber)
  const { dependents } = useGetDependents(idNumber)
  const { recipients } = useGetRecipients(idNumber)
  const [loading, setLoading] = useState(formInitialDataLoading)
  const [formData, setFormData] = useState({
    hasAnyBeneficiaries: data?.hasAnyBeneficiaries,
    impactOnValueLeftToBeneficiaries: data?.impactOnValueLeftToBeneficiaries,
    hasSomeoneFinanciallyDependentOn: data?.hasSomeoneFinanciallyDependentOn,
    hasDiscussedPlansWithFamilyOrBeneficiaries: data?.hasDiscussedPlansWithFamilyOrBeneficiaries,
    discussionDetails: data?.discussionDetails,
    willDiscussPlansWithFamilyOrBeneficiaries: data?.willDiscussPlansWithFamilyOrBeneficiaries,
    whyNotDiscussingWithFamilyOrBeneficiaries: data?.whyNotDiscussingWithFamilyOrBeneficiaries,
    isLeavingInheritanceToBeneficiariesImportant: data?.isLeavingInheritanceToBeneficiariesImportant,
    detailsOfLeavingInheritanceToBeneficiaries: data?.detailsOfLeavingInheritanceToBeneficiaries,
    whyLeavingInheritanceToBeneficiariesIsNotImportant: data?.whyLeavingInheritanceToBeneficiariesIsNotImportant,
    customerFeelingsAboutEquityReleaseImpactOnAsset: data?.customerFeelingsAboutEquityReleaseImpactOnAsset,
    isCustomerGiftingAnyReleasedMoney: data?.isCustomerGiftingAnyReleasedMoney,
    isGiftWithinHMRCLimitsAndExemptions: data?.isGiftWithinHMRCLimitsAndExemptions,
    contactDetailsOfWillBeneficiaries: data?.contactDetailsOfWillBeneficiaries,
    isReasonForNotInvolvingFamilyOrBeneficiaries: data?.isReasonForNotInvolvingFamilyOrBeneficiaries,
    detailsOfNotInvolvingFamilyOrBeneficiaries: data?.detailsOfNotInvolvingFamilyOrBeneficiaries,
    communicationStrategyForExclusiveBeneficiaryGain: data?.communicationStrategyForExclusiveBeneficiaryGain,
    wantsRecommendationForwardedToFamilyOrBeneficiaries: data?.wantsRecommendationForwardedToFamilyOrBeneficiaries,
    wantsRecommendationForwardedToRepresentativeOrSolicitor: data?.wantsRecommendationForwardedToRepresentativeOrSolicitor,
    agent: {
      name: data?.agent?.name,
      address: {
        formattedAddress: data?.powerOfAttorney?.address?.formattedAddress,
        address1: data?.powerOfAttorney?.address?.address1,
        address2: data?.powerOfAttorney?.address?.address2,
        town: data?.powerOfAttorney?.address?.town,
        county: data?.powerOfAttorney?.address?.county,
        country: data?.powerOfAttorney?.address?.country,
        postcode: data?.powerOfAttorney?.address?.postcode,
      },
      country: data?.agent?.country,
      email: data?.agent?.email,
    },
  })
  const flattenedFormData = commonUtils.flattenObject(formData)
  const [showAddressDetails, setShowAddressDetails] = useState(false)

  useEffect(() => {
    window.scroll({ top: 0, behavior: 'smooth' })
  }, [loading])

  useEffect(() => {
    setLoading(formInitialDataLoading)
    const newData = commonUtils.removeTypeNameFromObject(data)
    delete newData._id
    delete newData.agent?._id
    setFormData({ ...newData })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formInitialDataLoading])

  const handleAntdFormItemChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const parsedValue = commonUtils.parseValue(e.target?.value, e.target?.name)
    setFormData((prevState) => _.set(_.cloneDeep(prevState), e.target.name, parsedValue))
  }

  const handleCustomSelectChange = (name: string, value: string) => {
    setFormData((prevState) => _.set(_.cloneDeep(prevState), name, value))
  }

  const onPlaceSelect = ({ formattedAddress, address1, address2, town, county, country, postcode }: any) => {
    setFormData((prevState) => ({
      ...prevState,
      agent: {
        ...prevState.agent,
        address: {
          formattedAddress,
          address1,
          address2,
          town,
          county,
          country,
          postcode,
        },
      },
    }))

    form.setFieldValue('agent.address.formattedAddress', formattedAddress)
    form.setFieldValue('agent.address.address1', address1)
    form.setFieldValue('agent.address.address2', address2)
    form.setFieldValue('agent.address.town', town)
    form.setFieldValue('agent.address.county', county)
    form.setFieldValue('agent.address.country', country)
    form.setFieldValue('agent.address.postcode', postcode)
  }

  const showAddressDetailsChange = (checked: boolean) => {
    setShowAddressDetails(checked)
  }

  const handleSubmit = async () => {
    const { errors } = await addEditFamily({
      idNumber,
      formName: FORMS_ENUM.family,
      values: { family: formData },
    })
    if (errors?.length) {
      setLoading(false)
      const errMessage = commonHelper.parseGraphqlErrorMessage(errors)
      message.error(errMessage)
      return
    }
    message.success('Operation finished successfully')
  }

  const { handleSaveAndGoBack, handleSave, handleSaveAndContinue } = useGeneral({ handleSubmit, setLoading })

  const [saveType, setSaveType] = useState<'save' | 'saveAndGoBack' | 'saveAndContinue'>('save')

  const onSubmit = () => {
    if (saveType === 'saveAndGoBack') {
      handleSaveAndGoBack()
    } else if (saveType === 'saveAndContinue') {
      handleSaveAndContinue()
    } else if (saveType === 'save') {
      handleSave()
    }
  }

  const onFinishFailed = (errorInfo: any) => {
    message.error('please fill out every required field')
  }

  const handleAddBeneficiary = () => {
    eventManager.openBeneficiaryModal(
      {},
      {
        confirm: () => {},
      },
    )
  }

  const beneficiariesDataSource =
    beneficiaries.map((beneficiary: any) => ({
      id: beneficiary._id,
      name: beneficiary.name,
      age: beneficiary.age,
      relationship: beneficiary.relationship,
      isFinanciallyDependentOnClient: beneficiary.isFinanciallyDependentOnClient,
    })) || []

  const [selectedBeneficiaries, setSelectedBeneficiaries] = useState({})
  const displayBeneficiariesDeleteButton = !!Object.keys(selectedBeneficiaries).length
  const displayBeneficiaryEditButton = Object.keys(selectedBeneficiaries).length === 1

  const setSelectedBeneficiariesRows = (selectedRowObj: any) => {
    setSelectedBeneficiaries(selectedRowObj)
  }

  const handleDeleteBeneficiaries = async () => {
    setLoading(true)
    const beneficiaries_IdToDelete = Object.keys(selectedBeneficiaries)
    const { errors } = await deleteBeneficiaries({ _ids: beneficiaries_IdToDelete, idNumber })
    if (errors?.length) {
      setLoading(false)
      const errMessage = commonHelper.parseGraphqlErrorMessage(errors)
      message.error(errMessage)
      return
    }
    message.success('Deleted successfully')
    setLoading(false)
    refetch()
    setSelectedBeneficiaries({})
  }

  const handleDeleteBeneficiariesModal = () => {
    eventManager.openConfirmDeleteModal(
      { header: 'Do you really want to delete? This process can’t be undone.' },
      {
        confirm: () => handleDeleteBeneficiaries(),
      },
    )
  }

  const handleAddDependent = () => {
    eventManager.openDependentModal(
      {},
      {
        confirm: () => {},
      },
    )
  }

  const dependentsDataSource =
    dependents.map((dependent: any) => ({
      id: dependent._id,
      name: dependent.name,
      age: dependent.age,
      relationship: dependent.relationship,
    })) || []

  const [selectedDependents, setSelectedDependents] = useState({})
  const displayDependentsDeleteButton = !!Object.keys(selectedDependents).length
  const displayDependentEditButton = Object.keys(selectedDependents).length === 1

  const setSelectedDependentsRows = (selectedRowObj: any) => {
    setSelectedDependents(selectedRowObj)
  }

  const handleDeleteDependents = async () => {
    setLoading(true)
    const beneficiaries_IdToDelete = Object.keys(selectedDependents)
    const { errors } = await deleteDependents({ _ids: beneficiaries_IdToDelete, idNumber })
    if (errors?.length) {
      setLoading(false)
      const errMessage = commonHelper.parseGraphqlErrorMessage(errors)
      message.error(errMessage)
      return
    }
    message.success('Deleted successfully')
    setLoading(false)
    refetch()
    setSelectedDependents({})
  }

  const handleDeleteDependentsModal = () => {
    eventManager.openConfirmDeleteModal(
      { header: 'Do you really want to delete? This process can’t be undone.' },
      {
        confirm: () => handleDeleteDependents(),
      },
    )
  }

  const handleAddRecipient = () => {
    eventManager.openRecipientModal(
      {},
      {
        confirm: () => {},
      },
    )
  }

  const recipientsDataSource =
    recipients.map((recipient: any) => ({
      id: recipient._id,
      name: recipient.name,
      relationship: recipient.relationship,
      postcode: recipient?.address?.postcode || 'N/A',
      email: recipient.email,
    })) || []

  const [selectedRecipients, setSelectedRecipients] = useState({})
  const displayRecipientsDeleteButton = !!Object.keys(selectedRecipients).length
  const displayRecipientEditButton = Object.keys(selectedRecipients).length === 1

  const setSelectedRecipientsRows = (selectedRowObj: any) => {
    setSelectedRecipients(selectedRowObj)
  }

  const handleDeleteRecipients = async () => {
    setLoading(true)
    const recipients_IdToDelete = Object.keys(selectedRecipients)
    const { errors } = await deleteRecipients({ _ids: recipients_IdToDelete, idNumber })
    if (errors?.length) {
      setLoading(false)
      const errMessage = commonHelper.parseGraphqlErrorMessage(errors)
      message.error(errMessage)
      return
    }
    message.success('Deleted successfully')
    setLoading(false)
    refetch()
    setSelectedRecipients({})
  }

  const handleDeleteRecipientsModal = () => {
    eventManager.openConfirmDeleteModal(
      { header: 'Do you really want to delete? This process can’t be undone.' },
      {
        confirm: () => handleDeleteRecipients(),
      },
    )
  }

  const handleBeneficiaryEditModal = () => {
    eventManager.openBeneficiaryModal(
      { _id: Object.keys(selectedBeneficiaries)[0], isEdit: true },
      {
        confirm: () => {},
      },
    )
  }

  const handleDependentEditModal = () => {
    eventManager.openDependentModal(
      { _id: Object.keys(selectedDependents)[0], isEdit: true },
      {
        confirm: () => {},
      },
    )
  }

  const handleRecipientEditModal = () => {
    eventManager.openRecipientModal(
      { _id: Object.keys(selectedRecipients)[0], isEdit: true },
      {
        confirm: () => {},
      },
    )
  }

  return {
    onFinishFailed,
    onSubmit,
    setSaveType,
    loading,
    formData,
    flattenedFormData,
    handleAntdFormItemChange,
    handleCustomSelectChange,
    onPlaceSelect,
    showAddressDetails,
    showAddressDetailsChange,
    handleAddBeneficiary,
    beneficiariesDataSource,
    setSelectedBeneficiariesRows,
    displayBeneficiariesDeleteButton,
    handleDeleteBeneficiariesModal,
    dependentsDataSource,
    handleAddDependent,
    setSelectedDependentsRows,
    displayDependentsDeleteButton,
    handleDeleteDependentsModal,
    recipientsDataSource,
    handleAddRecipient,
    setSelectedRecipientsRows,
    displayRecipientsDeleteButton,
    handleDeleteRecipientsModal,
    displayBeneficiaryEditButton,
    handleBeneficiaryEditModal,
    displayDependentEditButton,
    handleDependentEditModal,
    displayRecipientEditButton,
    handleRecipientEditModal,
    form,
  }
}

export default useFamily
