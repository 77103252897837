import { useState, useCallback, memo } from 'react'
import { TypeRowSelection } from '@inovua/reactdatagrid-community/types'
import ReactDataGrid from '@inovua/reactdatagrid-community'
import '@inovua/reactdatagrid-community/index.css'
import { GridProps } from './Grid.types'

// import people from './people'

const rowClassName = ({ data }: Record<string, any>) => {
  if (data.name === 'Adams LLC') {
    return 'global-custom-row-green'
  }
  if (data.name === 'Waelchi LLC') {
    return 'global-custom-row-dark-magenta'
  }

  return 'global-custom-row'
}

const Grid = ({ columns, dataSource, groups, checkboxColumn, setSelectedRows, onRowClick, rowHeight = 40, headerHeight = 40 }: GridProps) => {
  const [selected, setSelected] = useState<TypeRowSelection>({})

  const height = dataSource.length * rowHeight + headerHeight
  const gridStyle = { minHeight: `${height}px` }

  const onSelectionChange = useCallback(({ selected }: { selected: TypeRowSelection }) => {
    setSelectedRows(selected)
    setSelected(selected)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <ReactDataGrid
      headerHeight={headerHeight}
      idProperty="id"
      sortable={true}
      allowUnsort={true}
      checkboxColumn={checkboxColumn}
      selected={selected}
      onSelectionChange={onSelectionChange}
      rowClassName={rowClassName}
      style={gridStyle}
      columns={columns}
      dataSource={dataSource}
      groups={groups}
      onRowClick={onRowClick}
      rowHeight={rowHeight}
    />
  )
}

export default memo(Grid)
