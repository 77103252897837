import { earlyRepaymentChargeOptions } from 'pages/QuickSearch/Tabs/FurtherInformationTab/FurtherInformationTab.helper'
import { commonUtils } from 'utils'

export const clientDetailsColumns = [
  {
    name: 'item',
    header: 'Item',
    defaultFlex: 1,
    type: 'text',
  },
  {
    name: 'value',
    header: 'Value',
    defaultFlex: 1,
    type: 'text',
  },
]

export const generateClientDetailsDataSource = (userData: any) => {
  const { firstName, lastName, gender, dateOfBirth } = userData
  const age = commonUtils.calculateAge(dateOfBirth)
  return [
    {
      id: 'cscascasc1',
      item: 'Name',
      value: `${firstName} ${lastName}`,
    },
    {
      id: 'cscascasc2',
      item: 'Sex',
      value: gender,
    },
    {
      id: 'cscascasc3',
      item: 'Date of birth',
      value: dateOfBirth,
    },
    {
      id: 'cscascasc4',
      item: 'Age',
      value: age,
    },
    {
      id: 'cscascasc5',
      item: 'Medical details',
      value: 'No',
    },
  ]
}

export const policyDetailsColumns = [
  {
    name: 'item',
    header: 'Item',
    defaultFlex: 1,
    type: 'text',
  },
  {
    name: 'value',
    header: 'Value',
    defaultFlex: 1,
    type: 'text',
  },
]

export const generatePolicyDetailsDataSource = ({ quickSearchData }: any) => {
  const {
    propertyAndLanding: {
      propertyValue,
      propertyType,
      inheritanceProtection,
      inheritanceProtectPercentage,
      postcode,
      houseNameOrNumber,
      location,
      tenureType,
      leasePeriod,
      // requiredFunding,
      isExLocalAuthorityOrMODProperty,
      maximumPayment,
      paymentAmountOnInitialAdvance,
    },
    furtherInformation: { residenceType, adviceFee, productResearchEnvironment, earlyRepaymentCharge },
  } = quickSearchData

  return [
    {
      id: 'cscascasc0',
      item: 'Product Research Environment',
      value: productResearchEnvironment,
    },
    {
      id: 'cscascasc111',
      item: 'Early Repayment Charge',
      value: earlyRepaymentCharge?.map((chargeType: string) => earlyRepaymentChargeOptions.find((option) => option.value === chargeType)?.label).join(', '),
    },
    {
      id: 'cscascasc123',
      item: 'The House Number or Name of The Property',
      value: houseNameOrNumber,
    },
    {
      id: 'cscascasc1',
      item: 'Property value',
      value: `£${commonUtils.formatNumberWithCommas(propertyValue)}`,
    },
    {
      id: 'cscascasc2',
      item: 'Property type',
      value: propertyType,
    },
    {
      id: 'cscascasc3',
      item: 'Inheritance protection',
      value: inheritanceProtection ? 'Yes' : 'No',
    },
    ...(inheritanceProtection
      ? [
          {
            id: 'cscascasc31',
            item: 'Protect (%)',
            value: `${inheritanceProtectPercentage}%`,
          },
        ]
      : []),
    {
      id: 'cscascasc4',
      item: 'Postcode',
      value: postcode,
    },
    {
      id: 'cscascasc5',
      item: 'Location',
      value: location,
    },
    {
      id: 'cscascasc6',
      item: 'Tenure',
      value: tenureType,
    },
    ...(tenureType === 'Leasehold'
      ? [
          {
            id: 'cscascasccc5',
            item: 'Lease Term (Years)',
            value: leasePeriod,
          },
        ]
      : []),
    // {
    //   id: 'cscascasc7',
    //   item: 'Funding required',
    //   value: requiredFunding
    //     ?.map((requiredFundingOption: string) => requiredFundingOptions.find((option) => option.value === requiredFundingOption)?.label)
    //     .join(', '),
    // },
    // {
    //   id: 'cscascasc8',
    //   item: 'Include reserve facility',
    //   value: 'No',
    // },
    {
      id: 'cscascasc9',
      item: 'Initial payment amount required',
      value: maximumPayment ? 'Maximum amount' : 'Specified amount',
    },
    ...(!maximumPayment
      ? [
          {
            id: 'cscascasc911',
            item: 'Specified amount',
            value: `£${commonUtils.formatNumberWithCommas(paymentAmountOnInitialAdvance)}`,
          },
        ]
      : []),
    // {
    //   id: 'cscascasc10',
    //   item: 'Commencement date',
    //   value: '21/01/2023',
    // },
    {
      id: 'cscascasc11',
      item: 'Ex-local authority',
      value: isExLocalAuthorityOrMODProperty ? 'Yes' : 'No',
    },
    {
      id: 'cscascasc12',
      item: 'Residence type',
      value: residenceType,
    },
    // {
    //   id: 'cscascasc13',
    //   item: 'Product types',
    //   value: 'Lifetime mortgage – Roll up, Lifetime mortgage – Interest only / servicing',
    // },
    // {
    //   id: 'cscascasc14',
    //   item: 'Early repayment charges',
    //   value: 'Fixed charges, Variable charges',
    // },
    // {
    //   id: 'cscascasc15',
    //   item: 'Products to quote for',
    //   value: 'All products',
    // },
    // {
    //   id: 'cscascasc16',
    //   item: 'Sell On fee specified',
    //   value: 'No',
    // },
    // {
    //   id: 'cscascasc17',
    //   item: 'Interest servicing level',
    //   value: 'Full interest amount',
    // },
    // {
    //   id: 'cscascasc18',
    //   item: 'Percentage interest to service',
    //   value: '100',
    // },
    {
      id: 'cscascasc19',
      item: 'Advice Fee',
      value: `£${commonUtils.formatNumberWithCommas(adviceFee)}`,
    },
    // {
    //   id: 'cscascasc20',
    //   item: 'Club Membership',
    //   value: 'Premier Equity Release Club',
    // },
  ]
}
