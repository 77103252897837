import { useEffect, useState } from 'react'
import { message } from 'antd'
import { TypeRowSelection } from '@inovua/reactdatagrid-community/types'

import { commonHelper } from 'helpers'
import { commonUtils, eventManager } from 'utils'

import {
  useGetCustomers,
  useGetExpenditureInfo,
  useGetExpendituresDetails,
  useGetFuturePensions,
  useGetIncomeSources,
  useGetOtherLiabilities,
} from 'services/clients/clients.service'

import useGeneral from './useGeneral'
import { liabilityTypeOptions, monthlyCostChangePeriodOptions } from 'modals/EditLiabilityModal/EditLiabilityModal.helper'
import { expenditureTypeOptions } from 'modals/ExpenditureModal/ExpenditureDetailsModal.helper'
import { addEditFormMutation, deleteExpendituresDetailsMutation } from 'gql/client'
import { useCustomMutation } from 'services/shared/mutation'
import { TAddEditFormMutationPayload, DeleteWithIdNumberAnd_IdsPayload } from 'services/shared/mutation.payload'
import { getCustomersOptions } from 'utils/getCustomersOptions'
import { periodOptions } from 'utils/commonOptions'
import { ILabelAndValue } from 'interfaces/common/labelAndValue'
import { calculateTotalMonthlyIncome } from 'utils/totalMonthlyIncome'
import { calculateTotalMonthlyExpenditure } from 'utils/totalMonthlyExpenditure'
import { calculateDisposableIncome } from 'utils/disposableIncome'
import { FORMS_ENUM } from 'enum/form.enum'

const useExpenditure = () => {
  const addEditExpenditure = useCustomMutation<TAddEditFormMutationPayload>({ mutation: addEditFormMutation, refetchQueries: ['getClientFormsProgress'] })
  const deleteExpendituresDetails = useCustomMutation<DeleteWithIdNumberAnd_IdsPayload>({
    mutation: deleteExpendituresDetailsMutation,
    refetchQueries: ['getExpendituresDetails'],
  })
  const urlParams = new URLSearchParams(window.location.search)
  const idNumber = Number(urlParams.get('idNumber'))
  const { otherLiabilities } = useGetOtherLiabilities(idNumber)
  const { data, loading: formInitialDataLoading, refetch } = useGetExpenditureInfo(idNumber)
  const { expendituresDetails } = useGetExpendituresDetails(idNumber)
  const { incomeSources } = useGetIncomeSources(idNumber)
  const { futurePensions } = useGetFuturePensions(idNumber)
  const [loading, setLoading] = useState(formInitialDataLoading)
  const [formData, setFormData] = useState({
    isDifficultToPayBills: data?.isDifficultToPayBills,
  })
  const { data: customersData } = useGetCustomers(idNumber)
  const customersOptions = getCustomersOptions(customersData, true)

  useEffect(() => {
    window.scroll({ top: 0, behavior: 'smooth' })
  }, [loading])

  useEffect(() => {
    setLoading(formInitialDataLoading)
    const newData = commonUtils.removeTypeNameFromObject(data)
    delete newData._id
    setFormData({ ...newData })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formInitialDataLoading])

  const handleAntdFormItemChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const parsedValue = commonUtils.parseValue(e.target?.value, e.target?.name)
    setFormData((prevState) => ({
      ...prevState,
      [e.target.name]: parsedValue,
    }))
  }

  const handleSubmit = async () => {
    const { errors } = await addEditExpenditure({
      idNumber,
      formName: FORMS_ENUM.expenditure,
      values: { expenditure: formData },
    })
    if (errors?.length) {
      setLoading(false)
      const errMessage = commonHelper.parseGraphqlErrorMessage(errors)
      message.error(errMessage)
      return
    }
    message.success('Operation finished successfully')
  }

  const { handleSaveAndGoBack, handleSave, handleSaveAndContinue } = useGeneral({ handleSubmit, setLoading })

  const [saveType, setSaveType] = useState<'save' | 'saveAndGoBack' | 'saveAndContinue'>('save')

  const onSubmit = () => {
    if (saveType === 'saveAndGoBack') {
      handleSaveAndGoBack()
    } else if (saveType === 'saveAndContinue') {
      handleSaveAndContinue()
    } else if (saveType === 'save') {
      handleSave()
    }
  }

  const onFinishFailed = (errorInfo: any) => {
    message.error('please fill out every required field')
  }

  const liabilitiesDataSource =
    otherLiabilities.map((liability: any) => {
      return {
        id: liability._id,
        liabilityHolder: customersOptions.find((item: ILabelAndValue) => item.value === liability.liabilityHolder)?.label,
        liabilityType: liabilityTypeOptions?.find((item: ILabelAndValue) => item.value === liability.liabilityType)?.label,
        monthlyCost: liability.monthlyCost,
        willMonthlyCostChange: liability.willMonthlyCostChange,
        monthlyCostChangePeriod: monthlyCostChangePeriodOptions?.find((item: ILabelAndValue) => item.value === liability.monthlyCostChangePeriod)?.label,
        expenditureWillChangeTo: liability.monthlyCostWillChangeTo,
      }
    }) || []

  const expenditureDataSource =
    expendituresDetails.map((expenditure: any) => {
      return {
        id: expenditure._id,
        customer: customersOptions.find((item: ILabelAndValue) => item.value === expenditure.expenditureHolder)?.label,
        expenditurePeriod: periodOptions?.find((item: ILabelAndValue) => item.value === expenditure.expenditurePeriod)?.label,
        expenditureType: expenditureTypeOptions?.find((item: ILabelAndValue) => item.value === expenditure.expenditureType)?.label,
        expenditureAmount: expenditure.expenditureAmount,
      }
    }) || []

  const handleAddButton = () => {
    eventManager.openExpenditureModal(
      {},
      {
        confirm: () => {},
      },
    )
  }

  const [selected, setSelected] = useState<TypeRowSelection>({})
  const displayDeleteButton = !!Object.keys(selected as object).length
  const displayEditButton = Object.keys(selected as object).length === 1

  const setSelectedRows = (selectedRowObj: TypeRowSelection) => {
    setSelected(selectedRowObj)
  }

  const handleDelete = async () => {
    setLoading(true)
    const expendituresDetails_IdToDelete = Object.keys(selected as object)
    const { errors } = await deleteExpendituresDetails({ _ids: expendituresDetails_IdToDelete, idNumber })
    if (errors?.length) {
      setLoading(false)
      const errMessage = commonHelper.parseGraphqlErrorMessage(errors)
      message.error(errMessage)
      return
    }
    message.success('Deleted successfully')
    setLoading(false)
    refetch()
    setSelected({})
  }

  const handleDeleteModal = () => {
    eventManager.openConfirmDeleteModal(
      { header: 'Do you really want to delete? This process can’t be undone.' },
      {
        confirm: () => handleDelete(),
      },
    )
  }

  const handleEditModal = () => {
    eventManager.openExpenditureModal(
      { _id: Object.keys(selected as object)[0], isEdit: true },
      {
        confirm: () => {},
      },
    )
  }

  const incomeTableDataSource = calculateTotalMonthlyIncome(incomeSources, futurePensions)
  const expenditureTableDataSource = calculateTotalMonthlyExpenditure(expendituresDetails, otherLiabilities)
  const disposableIncomeTableDataSource = calculateDisposableIncome(incomeTableDataSource, expenditureTableDataSource)

  return {
    onFinishFailed,
    onSubmit,
    setSaveType,
    loading,
    formData,
    handleAntdFormItemChange,
    liabilitiesDataSource,
    handleAddButton,
    expenditureDataSource,
    setSelectedRows,
    displayDeleteButton,
    handleDeleteModal,
    displayEditButton,
    handleEditModal,
    incomeTableDataSource,
    expenditureTableDataSource,
    disposableIncomeTableDataSource,
  }
}

export default useExpenditure
