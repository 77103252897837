import { TypeRowSelection } from '@inovua/reactdatagrid-community/types'
import { Form, FormInstance, message } from 'antd'
import { deleteClientFromStatsMutation, linkClientMutation } from 'gql/client'
import { commonHelper } from 'helpers'
import { ILabelAndValue } from 'interfaces/common/labelAndValue'
import { useEffect, useState } from 'react'
import { useGetClientsForLinkingInStats, useGetClientsStatisticsPagination } from 'services/clients/clients.service'
import { useCustomMutation } from 'services/shared/mutation'
import { IdNumbersPayload } from 'services/shared/mutation.payload'
import { eventManager } from 'utils'

interface IUseLinkClientResponse {
  open: boolean
  handleDrawerClose: () => void
  loading: boolean
  clientsOptions: ILabelAndValue[]
  handleCustomSelectChange: (name: string, value: string) => void
  formData: ILinkClientFormData
  handleFormFieldChange: (event: React.FormEvent<HTMLFormElement>) => void
  handleSubmit: () => void
  form: FormInstance
  detailsDataSource: IDetailsDataSource[]
  count: number
  limit: number
  refetch: () => void
  statusOptions: ILabelAndValue[]
  Status: typeof Status
  handleTabChange: (key: string) => void
  activeTab: string
  handleEdit: () => void
  setSelectedRows: (selectedRowObj: Record<string, any>) => void
  displayEditButton: boolean
  displayDeleteButton: boolean
  handleDeleteModal: () => void
  formState: 'add' | 'edit'
  handleOpenDrawer: () => void
}

interface IClient {
  _id: string
  idNumber: number
  customerFirst: {
    firstName: string
    lastName: string
  }
}

export interface ILinkClientFormData {
  idNumber: number | null
  clientName: string
  dateOfApplication: string
  lenderOrProvider: string
  feeType: string
  amount: number | null
  completionDate: string
  ntuDate: string
  status: string
}

interface IClient extends ILinkClientFormData {
  _id: string
  companyName: string
  advisorName: string
}

export interface IDetailsDataSource {
  id: number
  name: string
  adviser: string
  clientName: string
  dateOfApplication: string
  lenderProvider: string
  feeType: string
  amount: number
  completionDate: string
  ntuDate: string
  status: string
}

const skip = 0
const limit = 20

export enum Status {
  writtenBusiness = 'writtenBusiness',
  onRisk = 'onRisk',
  commissionPaid = 'commissionPaid',
  ntu = 'ntu',
}

const statusOptions = [
  {
    label: 'Written Business',
    value: Status.writtenBusiness,
  },
  {
    label: 'On Risk',
    value: Status.onRisk,
  },
  {
    label: 'Commission Paid',
    value: Status.commissionPaid,
  },
  {
    label: 'NTU',
    value: Status.ntu,
  },
]

const useLinkClient = (): IUseLinkClientResponse => {
  const [formState, setFormState] = useState<'add' | 'edit'>('add')
  const [selected, setSelected] = useState<TypeRowSelection>({})
  const [open, setOpen] = useState(false)
  const [loading, setLoading] = useState(false)
  const [clientsOptions, setClientsOptions] = useState<ILabelAndValue[]>([])
  const [activeTab, setActiveTab] = useState(Status.writtenBusiness)
  const linkClient = useCustomMutation<ILinkClientFormData>({ mutation: linkClientMutation })
  const { clients, loading: clientsLoading } = useGetClientsForLinkingInStats({ skip: 0, isAddedInStatistics: false })
  const { clientsStatistics, refetch, count } = useGetClientsStatisticsPagination({ skip, limit, status: Status.writtenBusiness })
  const deleteClientFromStats = useCustomMutation<IdNumbersPayload>({ mutation: deleteClientFromStatsMutation })
  const [form] = Form.useForm()

  const [formData, setFormData] = useState<ILinkClientFormData>({
    idNumber: null,
    clientName: '',
    dateOfApplication: '',
    lenderOrProvider: '',
    feeType: '',
    amount: null,
    completionDate: '',
    ntuDate: '',
    status: Status.writtenBusiness,
  })

  useEffect(() => {
    refetch({
      skip,
      limit,
      status: activeTab,
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeTab])

  useEffect(() => {
    if (clients) {
      const options = clients.map((client: IClient) => {
        let name = ''
        if (client?.customerFirst?.firstName) name += client?.customerFirst?.firstName
        if (client?.customerFirst?.lastName) name += ` ${client?.customerFirst?.lastName}`
        return {
          label: name,
          value: client.idNumber,
        }
      })
      setClientsOptions(options)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [clientsLoading])

  const handleCustomSelectChange = (name: string, value: string) => {
    if (name === 'idNumber') {
      const clientFullName = clientsOptions.find((client) => client.value === Number(value))?.label
      setFormData((prevState) => ({
        ...prevState,
        [name]: Number(value),
        clientName: clientFullName as string,
      }))
    } else {
      setFormData((prevState) => ({
        ...prevState,
        [name]: value,
      }))
    }
  }

  const handleFormFieldChange = (event: React.FormEvent<HTMLFormElement>) => {
    const { name, value } = event.target as HTMLInputElement
    setFormData((prevState) => {
      switch (name) {
        case 'amount':
          return {
            ...prevState,
            [name]: Number(value),
          }
        default:
          return {
            ...prevState,
            [name]: value,
          }
      }
    })
  }

  const clearDrawerData = () => {
    form.resetFields()
    setFormData({
      idNumber: null,
      clientName: '',
      dateOfApplication: '',
      lenderOrProvider: '',
      feeType: '',
      amount: null,
      completionDate: '',
      ntuDate: '',
      status: Status.writtenBusiness,
    })
  }

  const handleSubmit = async () => {
    form.validateFields().then(async () => {
      setLoading(true)

      const { errors } = await linkClient(formData)
      if (errors?.length) {
        setLoading(false)
        const errMessage = commonHelper.parseGraphqlErrorMessage(errors)
        message.error(errMessage)
        return
      }
      message.success('New client added successfully')

      setLoading(false)
      setOpen(false)
      clearDrawerData()
      refetch()
    })
  }

  const detailsDataSource = clientsStatistics.map((client: IClient) => {
    return {
      id: client.idNumber,
      idNumber: client.idNumber,
      companyName: client.companyName,
      advisorName: client.advisorName,
      clientName: client.clientName,
      dateOfApplication: client.dateOfApplication,
      lenderProvider: client.lenderOrProvider,
      feeType: client.feeType,
      amount: client.amount,
      completionDate: client.completionDate,
      ntuDate: client.ntuDate,
      status: client.status,
    }
  })

  /**
   *
   * @param key this is active tab number, but first time when page loads its on 1's tab and that doesn't fire this function
   */
  const handleTabChange = (key: string) => {
    setActiveTab(key as Status)
  }

  const setSelectedRows = (selectedRowObj: TypeRowSelection) => {
    setSelected(selectedRowObj)
  }

  const displayEditButton = Object.keys(selected as object).length === 1
  const displayDeleteButton = Object.keys(selected as object).length > 0

  const handleEdit = () => {
    setOpen(true)
    setFormState('edit')
    const userToEditIdNumber = Object.keys(selected as object)[0]
    const userToEdit = detailsDataSource.find((user: any) => user.idNumber === Number(userToEditIdNumber))
    setFormData({
      idNumber: userToEdit.idNumber,
      clientName: userToEdit.clientName,
      dateOfApplication: userToEdit.dateOfApplication,
      lenderOrProvider: userToEdit.lenderProvider,
      feeType: userToEdit.feeType,
      amount: userToEdit.amount,
      completionDate: userToEdit.completion,
      ntuDate: userToEdit.ntuata,
      status: userToEdit.status,
    })
    form.setFieldsValue({
      idNumber: userToEdit.idNumber,
      clientName: userToEdit.clientName,
      dateOfApplication: userToEdit.dateOfApplication,
      lenderOrProvider: userToEdit.lenderProvider,
      feeType: userToEdit.feeType,
      amount: userToEdit.amount,
      completionDate: userToEdit.completion,
      ntuDate: userToEdit.ntuata,
      status: userToEdit.status,
    })
  }

  const handleDelete = async () => {
    setLoading(true)
    const clientIdNumbersToDelete = Object.keys(selected as object)
    const { errors } = await deleteClientFromStats({ idNumbers: clientIdNumbersToDelete })
    if (errors?.length) {
      setLoading(false)
      const errMessage = commonHelper.parseGraphqlErrorMessage(errors)
      message.error(errMessage)
      return
    }
    message.success('Deleted successfully')
    setLoading(false)
    refetch()
  }

  const handleDeleteModal = () => {
    eventManager.openConfirmDeleteModal(
      { header: 'Do you really want to delete? This process can’t be undone.' },
      {
        confirm: () => handleDelete(),
      },
    )
  }

  const handleDrawerClose = () => {
    setOpen(false)
    clearDrawerData()
    setFormState('add')
  }

  const handleOpenDrawer = () => {
    clearDrawerData()
    setOpen(true)
    setFormState('add')
  }

  return {
    open,
    handleDrawerClose,
    loading,
    clientsOptions,
    handleCustomSelectChange,
    formData,
    handleFormFieldChange,
    handleSubmit,
    form,
    detailsDataSource,
    count,
    limit,
    refetch,
    statusOptions,
    Status,
    handleTabChange,
    activeTab,
    setSelectedRows,
    displayEditButton,
    handleEdit,
    displayDeleteButton,
    handleDeleteModal,
    formState,
    handleOpenDrawer,
  }
}

export default useLinkClient
