import { useContext, useEffect, useState } from 'react'
import { message } from 'antd'

import { commonHelper } from 'helpers'
import { FormsContext } from 'contexts'
import { commonUtils } from 'utils'

import useGeneral from './useGeneral'
import { useGetBorrowingSolutionAndPrioritiesInfo, useGetOtherLiabilities, useGetObjectives } from 'services/clients/clients.service'
import { liabilityTypeOptions } from 'modals/EditLiabilityModal/EditLiabilityModal.helper'
import { fundsRequiredInOptions, purposeOfRaisingCapitalOptions } from 'modals/ObjectiveModal/ObjectiveModal.helper'
import { addEditFormMutation } from 'gql/client'
import { useCustomMutation } from 'services/shared/mutation'
import { TAddEditFormMutationPayload } from 'services/shared/mutation.payload'
import { ILabelAndValue } from 'interfaces/common/labelAndValue'
import { TCustomCheckboxValue } from 'components/CustomCheckbox/CustomCheckbox.types'
import { FORMS_ENUM } from 'enum/form.enum'

const useBorrowingSolutionAndPriorities = () => {
  const { setDetailedFormName, setBreadcrumbItems, breadcrumbItems } = useContext(FormsContext)
  const addEditBorrowingSolutionAndPriorities = useCustomMutation<TAddEditFormMutationPayload>({
    mutation: addEditFormMutation,
    refetchQueries: ['getClientFormsProgress'],
  })
  const urlParams = new URLSearchParams(window.location.search)
  const idNumber = Number(urlParams.get('idNumber'))
  const { objectives } = useGetObjectives(idNumber)
  const { otherLiabilities } = useGetOtherLiabilities(idNumber)
  const { data, loading: formInitialDataLoading } = useGetBorrowingSolutionAndPrioritiesInfo(idNumber)
  const [loading, setLoading] = useState(formInitialDataLoading)
  const [formData, setFormData] = useState({
    isThisEnoughCapital: data?.isThisEnoughCapital,
    isMaximumAdvanceNecessary: data?.isMaximumAdvanceNecessary,
    wantToUtilizeAnyProductCashBack: data?.wantToUtilizeAnyProductCashBack,
    isImportantToRetainHomeFullOwnership: data?.isImportantToRetainHomeFullOwnership,
    isCashReserveRequired: data?.isCashReserveRequired,
    isDrawdownSchemaFeeAcceptable: data?.isDrawdownSchemaFeeAcceptable,
    minimumWithdrawalAmount: data?.minimumWithdrawalAmount,
    isAdHocOrRegularPaymentLikable: data?.isAdHocOrRegularPaymentLikable,
    willingToMakeMonthlyPaymentsToServiceInterest: data?.willingToMakeMonthlyPaymentsToServiceInterest,
    wantsToServiceFullMonthlyInterest: data?.wantsToServiceFullMonthlyInterest,
    paymentType: data?.paymentType,
    paymentTypeDetails: data?.paymentTypeDetails,
    willConsiderPlansIfPaymentsAreNotMaintained: data?.willConsiderPlansIfPaymentsAreNotMaintained,
    isLifetimeMortgageRequired: data?.isLifetimeMortgageRequired,
    howLikelyRepayFullLoanInLifetime: data?.howLikelyRepayFullLoanInLifetime,
    timeFrameForLoanRepayment: data?.timeFrameForLoanRepayment,
    howToRepayLoanEarly: data?.howToRepayLoanEarly,
    otherWayHowToRepayLoanEarly: data?.otherWayHowToRepayLoanEarly,
    isVariableChargeAcceptable: data?.isVariableChargeAcceptable,
    isDefinedEarlyRepaymentChargeRequired: data?.isDefinedEarlyRepaymentChargeRequired,
    fixedPeriodForEarlyRepaymentCharge: data?.fixedPeriodForEarlyRepaymentCharge,
    repayLoanFreeIfMoveHomeAfterFiveYrs: data?.repayLoanFreeIfMoveHomeAfterFiveYrs,
    loanRepayImportantOnPartnerPassOrCare: data?.loanRepayImportantOnPartnerPassOrCare,
    isAbleToPayFeeForValuation: data?.isAbleToPayFeeForValuation,
    preferenceForLenderFeePayment: data?.preferenceForLenderFeePayment,
    isFixedInterestRateRequired: data?.isFixedInterestRateRequired,
    hasReceivedQuotationsForMortgage: data?.hasReceivedQuotationsForMortgage,
    recommendedProduct: data?.recommendedProduct,
    recommendedProductInterestRate: data?.recommendedProductInterestRate,
    hasPreviouslyAppliedForLoan: data?.hasPreviouslyAppliedForLoan,
    lendersToExclude: data?.lendersToExclude,
    areFundsRequiredQuickly: data?.areFundsRequiredQuickly,
    lenderPreference: data?.lenderPreference,
    detailReasonsForNeedingFundsQuickly: data?.detailReasonsForNeedingFundsQuickly,
    anticipateFutureCircumstancesChanges: data?.anticipateFutureCircumstancesChanges,
    detailsOfFutureCircumstancesChanges: data?.detailsOfFutureCircumstancesChanges,
    onlyDisplayApprovedProducts: data?.onlyDisplayApprovedProducts,
    attitudeToRisk: data?.attitudeToRisk,
  })

  useEffect(() => {
    window.scroll({ top: 0, behavior: 'smooth' })
  }, [loading])

  useEffect(() => {
    setLoading(formInitialDataLoading)
    const newData = commonUtils.removeTypeNameFromObject(data)
    delete newData._id
    setFormData({ ...newData })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formInitialDataLoading])

  const handleCustomSelectChange = (name: string, value: string) => {
    setFormData((prevState) => ({
      ...prevState,
      [name]: value,
    }))
  }

  const handleAntdFormItemChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const parsedValue = commonUtils.parseValue(e.target?.value, e.target?.name)
    setFormData((prevState) => ({
      ...prevState,
      [e.target?.name]: parsedValue,
    }))
  }

  const handleCustomCheckboxChange = ({ name, value }: { name: string; value: TCustomCheckboxValue }) => {
    setFormData((prevState) => ({
      ...prevState,
      [name]: value,
    }))
  }

  const handleSubmit = async () => {
    const { errors } = await addEditBorrowingSolutionAndPriorities({
      idNumber,
      formName: FORMS_ENUM.borrowingSolutionAndPriorities,
      values: {
        borrowingSolutionAndPriorities: {
          ...formData,
          otherWayHowToRepayLoanEarly: String(formData.otherWayHowToRepayLoanEarly),
          recommendedProduct: String(formData.recommendedProduct),
          detailReasonsForNeedingFundsQuickly: String(formData.detailReasonsForNeedingFundsQuickly),
          detailsOfFutureCircumstancesChanges: String(formData.detailsOfFutureCircumstancesChanges),
        },
      },
    })
    if (errors?.length) {
      setLoading(false)
      const errMessage = commonHelper.parseGraphqlErrorMessage(errors)
      message.error(errMessage)
      return
    }
    message.success('Operation finished successfully')
  }

  const { handleSaveAndGoBack, handleSave, handleSaveAndContinue } = useGeneral({ handleSubmit, setLoading })

  const [saveType, setSaveType] = useState<'save' | 'saveAndGoBack' | 'saveAndContinue'>('save')

  const onSubmit = () => {
    if (saveType === 'saveAndGoBack') {
      handleSaveAndGoBack()
    } else if (saveType === 'saveAndContinue') {
      handleSaveAndContinue()
    } else if (saveType === 'save') {
      handleSave()
    }
  }

  const onFinishFailed = (errorInfo: any) => {
    message.error('please fill out every required field')
  }

  let initialFundsTotalValue = 0
  let futureFundsTotalValue = 0

  const objectivesDataSource =
    objectives.map((objective: any) => {
      futureFundsTotalValue += objective.requiredAmount
      return {
        id: objective._id,
        objectiveSummery: objective.description,
        purposeOfRaisingCapital: purposeOfRaisingCapitalOptions?.find((item: ILabelAndValue) => item.value === objective.purposeOfRaisingCapital)?.label,
        requiredAmount: objective.requiredAmount,
        fundsTimescale: fundsRequiredInOptions?.find((item: ILabelAndValue) => item.value === objective.fundsRequiredIn)?.label,
      }
    }) || []

  const liabilitiesDataSource =
    otherLiabilities.map((liability: any) => {
      const amount = liability.balance + liability.earlyRepaymentCharges
      initialFundsTotalValue += amount
      const type = liabilityTypeOptions?.find((item: ILabelAndValue) => item.value === liability.liabilityType)?.label
      return {
        id: liability._id,
        objectiveSummery: `${type} - ${liability.description}`,
        purposeOfRaisingCapital: 'Debts',
        requiredAmount: amount,
        fundsTimescale: 'Now',
      }
    }) || []

  const dataSource = objectivesDataSource.concat(liabilitiesDataSource)

  const redirectToForm = (formName: string) => {
    setDetailedFormName(formName)
    setBreadcrumbItems([...breadcrumbItems.slice(0, -1), { key: `${breadcrumbItems.length + 1}`, text: formName }])
  }

  return {
    onFinishFailed,
    onSubmit,
    setSaveType,
    loading,
    formData,
    handleAntdFormItemChange,
    handleCustomSelectChange,
    handleCustomCheckboxChange,
    initialFundsTotalValue,
    futureFundsTotalValue,
    dataSource,
    redirectToForm,
  }
}

export default useBorrowingSolutionAndPriorities
