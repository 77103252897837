import Form from 'antd/lib/form'

import CustomFormItem from 'components/CustomFormItem'
import Loading from 'components/Loading/Loading'

import CustomFooter from 'pages/QuickSearch/CustomFooter/CustomFooter'
import { PercentIcon, PoundIcon } from 'assets/images'
import { propertyTypeOptions, tenureTypeOptions, yesNoOptions, locationOptions } from 'utils/commonOptions'

import { StyledContainer, StyledFormContainer, StyledTitleContainer, StyledTitle, StyledRequiredMsg } from './PropertyAndLandingTab.styles'
import { maximumPaymentOptions, lendingOptions } from './PropertyAndLandingTab.helper'
import usePropertyAndLandingTab from 'hooks/clients/QuickSearch/usePropertyAndLandingTab'
import CheckboxOptions from 'components/CheckboxOptions/CheckboxOptions'

const PropertyAndLandingTab = () => {
  const { loading, formData, handleAntdFormItemChange, handleCustomCheckboxChange, handleCustomSelectChange, handleSubmit, onFinishFailed } =
    usePropertyAndLandingTab()

  if (loading) {
    return <Loading />
  }

  return (
    <StyledContainer>
      <StyledFormContainer>
        <StyledTitleContainer>
          <StyledTitle>Property Details</StyledTitle>
          <StyledRequiredMsg>* For mandatory information</StyledRequiredMsg>
        </StyledTitleContainer>
        <Form
          layout="vertical"
          size="large"
          name="basic"
          onFinishFailed={onFinishFailed}
          onFinish={handleSubmit}
          initialValues={formData}
          style={{
            display: 'flex',
            flexDirection: 'column',
            rowGap: '12px',
            marginTop: '8px',
          }}
        >
          <CustomFormItem
            label="Property value (£) *"
            name="propertyValue"
            placeholder="Property value (£) *"
            inputPrefixImg={PoundIcon}
            prefixImgHeight={12}
            type="number"
            min={75000}
            onChange={handleAntdFormItemChange}
            rules={['isRequired']}
          />
          <CustomFormItem
            label="Property Type *"
            width={'100%'}
            placeholder="Property Type "
            onChange={handleCustomSelectChange}
            options={propertyTypeOptions}
            name="propertyType"
            type="select"
            rules={['isRequired']}
          />
          <CustomFormItem
            label="Inheritance protection *"
            onChange={handleAntdFormItemChange}
            options={yesNoOptions}
            name="inheritanceProtection"
            value={formData.inheritanceProtection}
            type="radio"
            rules={['isRequired']}
          />
          {formData.inheritanceProtection && (
            <CustomFormItem
              label="Protect (%) *"
              name="inheritanceProtectPercentage"
              placeholder="Protect (%)"
              onChange={handleAntdFormItemChange}
              rules={['isRequired', 'inheritanceProtectPercentage']}
              type="number"
              inputPrefixImg={PercentIcon}
              prefixImgHeight={12}
            />
          )}
          <CustomFormItem label="Postcode *" name="postcode" placeholder="Postcode *" onChange={handleAntdFormItemChange} rules={['isRequired']} />
          <CustomFormItem
            label="The house number or name of the property *"
            name="houseNameOrNumber"
            placeholder="The house number or name of the property *"
            onChange={handleAntdFormItemChange}
            rules={['isRequired']}
            value={formData.houseNameOrNumber}
          />
          <CustomFormItem
            label="Location *"
            onChange={handleAntdFormItemChange}
            options={locationOptions}
            name="location"
            value={formData.location}
            type="radio"
            rules={['isRequired']}
          />
          <CustomFormItem
            label="Tenure type *"
            onChange={handleAntdFormItemChange}
            options={tenureTypeOptions}
            name="tenureType"
            value={formData.tenureType}
            type="radio"
            rules={['isRequired']}
          />
          {formData.tenureType === 'Leasehold' && (
            <CustomFormItem
              value={formData.leasePeriod}
              label="Lease term remaining(years) *"
              name="leasePeriod"
              placeholder="Lease term remaining(years)"
              onChange={handleAntdFormItemChange}
              type="number"
              rules={['isRequired']}
            />
          )}
          <CustomFormItem
            label="Ex-local authority *"
            onChange={handleAntdFormItemChange}
            options={yesNoOptions}
            name="isExLocalAuthorityOrMODProperty"
            value={formData.isExLocalAuthorityOrMODProperty}
            type="radio"
            rules={['isRequired']}
          />
          <StyledTitle>Lending Details</StyledTitle>
          <CheckboxOptions
            label="Funding required *"
            onChange={handleCustomCheckboxChange}
            options={lendingOptions}
            name="lendingOptions"
            value={formData.lendingOptions}
          />
          {formData?.lendingOptions?.includes('initialAdvance') && (
            <>
              <StyledTitle>Initial advance</StyledTitle>
              <CustomFormItem
                label="Amount required *"
                onChange={handleAntdFormItemChange}
                options={maximumPaymentOptions}
                name="isMaximumPaymentOnInitialAdvance"
                value={formData.isMaximumPaymentOnInitialAdvance}
                type="radio"
                rules={['isRequired']}
              />
              {formData.isMaximumPaymentOnInitialAdvance === false && (
                <>
                  <CustomFormItem
                    label="Payment Amount *"
                    name="paymentAmountOnInitialAdvance"
                    placeholder="Payment Amount"
                    inputPrefixImg={PoundIcon}
                    prefixImgHeight={12}
                    onChange={handleAntdFormItemChange}
                    type="number"
                    rules={['isRequired']}
                  />
                  <CustomFormItem
                    toolTipText="Selecting this option will allow cashback to be deducted from the loan amount, so the cashback makes up the shortfall and meets the amount requested"
                    label="Include cashback in loan amount *"
                    onChange={handleAntdFormItemChange}
                    options={yesNoOptions}
                    name="includeCashbackInLoan"
                    value={formData.includeCashbackInLoan}
                    type="radio"
                    rules={['isRequired']}
                  />
                </>
              )}
            </>
          )}
          {formData?.lendingOptions?.includes('reserveFacility') && (
            <>
              <StyledTitle>Reserve facility</StyledTitle>
              <CustomFormItem
                label="Amount required *"
                onChange={handleAntdFormItemChange}
                options={maximumPaymentOptions}
                name="isMaximumPaymentOnReserveFacility"
                value={formData.isMaximumPaymentOnReserveFacility}
                type="radio"
                rules={['isRequired']}
              />
              {formData.isMaximumPaymentOnReserveFacility === false && (
                <CustomFormItem
                  label="Payment Amount *"
                  name="paymentAmountOnReserveFacility"
                  placeholder="Payment Amount"
                  inputPrefixImg={PoundIcon}
                  prefixImgHeight={12}
                  onChange={handleAntdFormItemChange}
                  type="number"
                  rules={['isRequired']}
                />
              )}
            </>
          )}

          {formData?.lendingOptions?.includes('regularPayments') && (
            <>
              <StyledTitle>Regular payments</StyledTitle>
              <CustomFormItem
                label="Amount required *"
                onChange={handleAntdFormItemChange}
                options={maximumPaymentOptions}
                name="isMaximumPaymentOnRegularPayments"
                value={formData.isMaximumPaymentOnRegularPayments}
                type="radio"
                rules={['isRequired']}
              />
              {formData.isMaximumPaymentOnRegularPayments === false && (
                <CustomFormItem
                  label="Payment Amount *"
                  name="paymentAmountOnRegularPayments"
                  placeholder="Payment Amount"
                  inputPrefixImg={PoundIcon}
                  prefixImgHeight={12}
                  onChange={handleAntdFormItemChange}
                  type="number"
                  rules={['isRequired']}
                />
              )}
            </>
          )}

          <CustomFooter loading={loading} buttonText="Next" buttonTheme="light" />
        </Form>
      </StyledFormContainer>
    </StyledContainer>
  )
}

export default PropertyAndLandingTab
