/* eslint-disable no-useless-computed-key */
import { Form } from 'antd'
import Loading from 'components/Loading'
import CustomFormItem from 'components/CustomFormItem'
import useEstatePlanning from 'hooks/clients/DetailedForms/useEstatePlanning'
import CustomFooter from 'pages/ClientDetails/CustomFooter/CustomFooter'

import {
  yesNoSureOptions,
  funeralDiscussOptions,
  specialistAdviceOptions,
  powerOfAttorneyOptions,
  coverFuneralCostOptions,
  careOptions,
  disclosedBeneficiariesTableColumns,
  yesNoSomeOptions,
  howToPayPrePaidFuneralPlanOptions,
  hasUndergoneNHSCareAssessmentOptions,
  ownershipTypeOptions,
  timeToReleaseSaleProceedsOptions,
  importanceOptions,
  escalationOptions,
  additionalDeathBenefitsOptions,
  deferredPeriodOptions,
} from './EstatePlanning.helper'
import { StyledContainer, StyledDescription, StyledTitle, StyledTitleContainer, StyledRequiredMsg, StyledBreakLine } from './EstatePlanning.styles'
import FormTable from 'components/FormTable/FormTable'
import { PoundIcon } from 'assets/images'
import CustomSwitch from 'components/CustomSwitch/CustomSwitch'
import { yesNoOptions } from 'utils/commonOptions'

const EstatePlanning = () => {
  const {
    onFinishFailed,
    onSubmit,
    setSaveType,
    loading,
    formData,
    flattenedFormData,
    handleCustomSwitch,
    skipRemainingCareQuestions,
    skipRemainingCareQuestionsChange,
    handleAntdFormItemChange,
    handleCustomSelectChange,
    disclosedBeneficiariesDataSource,
    customers,
    substituteDecisionMakerOptionsForCustomerFirst,
    substituteDecisionMakerOptionsForCustomerSecond,
  } = useEstatePlanning()

  if (loading) {
    return <Loading />
  }

  return (
    <StyledContainer>
      <StyledTitleContainer>
        <StyledTitle style={{ fontSize: '32px' }}>Estate Planning</StyledTitle>
        <StyledRequiredMsg>* For mandatory information</StyledRequiredMsg>
      </StyledTitleContainer>
      <StyledDescription>
        Estate Planning alongside making a will ensures your customers’ have a plan for their Estate before they pass away, ensuring assets are protected for
        the beneficiaries in their will. You can detail what plans they have in place on this page.
      </StyledDescription>
      <StyledTitle>Estate Planning for {customers?.customerFirst?.firstName}</StyledTitle>
      <FormTable
        columns={disclosedBeneficiariesTableColumns}
        dataSource={disclosedBeneficiariesDataSource}
        title="Your Disclosed Beneficiaries"
        checkboxColumn={false}
        tableContainerBgColor="#fce4ec"
      />
      <StyledBreakLine />
      <StyledTitle>Estate Planning</StyledTitle>
      <Form
        layout="vertical"
        size="large"
        name="basic"
        initialValues={flattenedFormData}
        style={{
          display: 'flex',
          flexDirection: 'column',
          rowGap: '12px',
          marginTop: '8px',
          width: '100%',
        }}
        onFinishFailed={onFinishFailed}
        onFinish={onSubmit}
      >
        <CustomFormItem
          label="Is the customer taking specialist advice regarding Estate Planning? *"
          width={'100%'}
          placeholder="Is the customer taking specialist advice regarding Estate Planning? *"
          onChange={handleCustomSelectChange}
          options={specialistAdviceOptions}
          name="customerFirst.isCustomerTakingSpecialistAdvice"
          type="select"
          rules={['isRequired']}
        />
        <StyledBreakLine />
        <StyledTitle>Your Will</StyledTitle>
        <CustomSwitch
          checked={formData?.customerFirst?.skipWillQuestions}
          onChange={(checked: boolean) => handleCustomSwitch({ checked, field: 'customerFirst.skipWillQuestions' })}
          label="Skip Will Questions?"
          alignSelf="flex-start"
        />
        {!formData?.customerFirst?.skipWillQuestions && (
          <>
            <CustomFormItem
              label="Have you made a Will?"
              onChange={handleAntdFormItemChange}
              options={yesNoOptions}
              name="customerFirst.hasWill"
              value={formData?.customerFirst?.hasWill}
              type="radio"
            />
            {formData?.customerFirst?.hasWill && (
              <>
                <CustomFormItem
                  label="Does your Will need to be updated?"
                  onChange={handleAntdFormItemChange}
                  options={yesNoOptions}
                  name="customerFirst.willNeedsUpdate"
                  value={formData?.customerFirst?.willNeedsUpdate}
                  type="radio"
                />
                <CustomFormItem
                  label="When did you last update your Will?"
                  name="customerFirst.willLastUpdatedDate"
                  placeholder="DD/MM/YYYY"
                  type="date"
                  onChange={handleAntdFormItemChange}
                />
              </>
            )}
            <CustomFormItem
              label="Would you like your Family Will to be one of your Objectives? *"
              onChange={handleAntdFormItemChange}
              options={yesNoOptions}
              name="customerFirst.wouldLikeFamilyWillToBeOneOfObjectives"
              value={formData?.customerFirst?.wouldLikeFamilyWillToBeOneOfObjectives}
              type="radio"
              rules={['isRequired']}
            />
            {formData?.customerFirst?.wouldLikeFamilyWillToBeOneOfObjectives && (
              <>
                <CustomFormItem
                  label="Amount required for Will"
                  name="customerFirst.amountOfWill"
                  placeholder="Amount required for Will"
                  inputPrefixImg={PoundIcon}
                  prefixImgHeight={12}
                  onChange={handleAntdFormItemChange}
                  type="number"
                />
                <CustomFormItem
                  label="Do you want to add above amount to loan?"
                  onChange={handleAntdFormItemChange}
                  options={yesNoSomeOptions}
                  name="customerFirst.addAmountOfWillToLoan"
                  value={formData?.customerFirst?.addAmountOfWillToLoan}
                  type="radio"
                />
                {formData?.customerFirst?.addAmountOfWillToLoan === 'someOfIt' && (
                  <CustomFormItem
                    label="How much do you need to add to loan for the Will?"
                    name="customerFirst.howMuchAmountOfWillAddToLoan"
                    placeholder="How much do you need to add to loan for the Will?"
                    inputPrefixImg={PoundIcon}
                    prefixImgHeight={12}
                    onChange={handleAntdFormItemChange}
                    type="number"
                  />
                )}
              </>
            )}
            <CustomFormItem
              label="Any other details about your conversation regarding Wills"
              placeholder="E.g will be shown here"
              value={formData?.customerFirst?.conversationDetailsAboutWill}
              name="customerFirst.conversationDetailsAboutWill"
              onChange={handleAntdFormItemChange}
              rows={4}
              type="textarea"
            />
          </>
        )}
        <StyledBreakLine />
        <StyledTitle>Power of Attorney</StyledTitle>
        <CustomSwitch
          checked={formData?.customerFirst?.skipPOAQuestions}
          onChange={(checked: boolean) => handleCustomSwitch({ checked, field: 'customerFirst.skipPOAQuestions' })}
          label="Skip Power of Attorney Questions?"
          alignSelf="flex-start"
        />
        {!formData?.customerFirst?.skipPOAQuestions && (
          <>
            <CustomFormItem
              label="Who would make decisions on your behalf if you were unable to due to mental or serious illness?"
              width={'100%'}
              placeholder="Who would make decisions on your behalf if you were unable to due to mental or serious illness?"
              onChange={handleCustomSelectChange}
              options={substituteDecisionMakerOptionsForCustomerFirst}
              name="customerFirst.substituteDecisionMaker"
              type="select"
            />
            <CustomFormItem
              label="Which Power of Attorney do you have (if applicable)?"
              width={'100%'}
              placeholder="Which Power of Attorney do you have (if applicable)?"
              onChange={handleCustomSelectChange}
              options={powerOfAttorneyOptions}
              name="customerFirst.powerOfAttorneyType"
              type="select"
            />
            <CustomFormItem
              label="Please provide details i.e. type of POA, named attorney/s, if attorneys act jointly/severally, if registered"
              placeholder="E.g will be shown here"
              value={formData?.customerFirst?.detailsAboutAttorney}
              name="customerFirst.detailsAboutAttorney"
              onChange={handleAntdFormItemChange}
              rows={4}
              type="textarea"
            />
            <CustomFormItem
              label="Has a copy of the POA been taken for the file? *"
              onChange={handleAntdFormItemChange}
              options={yesNoOptions}
              name="customerFirst.hasCopyOfPOA"
              value={formData?.customerFirst?.hasCopyOfPOA}
              type="radio"
              rules={['isRequired']}
            />
            <CustomFormItem
              label="Would you like to make a Power of Attorney one of your Objectives? *"
              onChange={handleAntdFormItemChange}
              options={yesNoOptions}
              name="customerFirst.wouldLikePOAToBeOneOfObjectives"
              value={formData?.customerFirst?.wouldLikePOAToBeOneOfObjectives}
              type="radio"
              rules={['isRequired']}
            />
            {formData?.customerFirst?.wouldLikePOAToBeOneOfObjectives && (
              <>
                <CustomFormItem
                  label="Amount required for Power of Attorney"
                  name="customerFirst.amountForPOA"
                  placeholder="Amount required for Power of Attorney"
                  inputPrefixImg={PoundIcon}
                  prefixImgHeight={12}
                  onChange={handleAntdFormItemChange}
                  type="number"
                />
                <CustomFormItem
                  label="Do you want to add above amount to loan?"
                  onChange={handleAntdFormItemChange}
                  options={yesNoSomeOptions}
                  name="customerFirst.addAmountForPOAToLoan"
                  value={formData?.customerFirst?.addAmountForPOAToLoan}
                  type="radio"
                />
                {formData?.customerFirst?.addAmountForPOAToLoan === 'someOfIt' && (
                  <CustomFormItem
                    label="How much do you need to add to loan for the Power of Attorney?"
                    name="customerFirst.howMuchAmountForPOAAddToLoan"
                    placeholder="How much do you need to add to loan for the Power of Attorney?"
                    inputPrefixImg={PoundIcon}
                    prefixImgHeight={12}
                    onChange={handleAntdFormItemChange}
                    type="number"
                  />
                )}
              </>
            )}
            <CustomFormItem
              label="Customer's opinion/decision regarding a Power of Attorney"
              placeholder="E.g will be shown here"
              value={formData?.customerFirst?.customerOpinionRegardingPOA}
              name="customerFirst.customerOpinionRegardingPOA"
              onChange={handleAntdFormItemChange}
              rows={4}
              type="textarea"
            />
          </>
        )}
        <StyledBreakLine />
        <StyledTitle>Your Funeral</StyledTitle>
        <CustomSwitch
          checked={formData?.customerFirst?.skipFuneralQuestions}
          onChange={(checked: boolean) => handleCustomSwitch({ checked, field: 'customerFirst.skipFuneralQuestions' })}
          label="Skip Funeral Questions?"
          alignSelf="flex-start"
        />
        {!formData?.customerFirst?.skipFuneralQuestions && (
          <>
            <CustomFormItem
              label="Would you like to cover the cost of your funeral for your family when you die?"
              onChange={handleAntdFormItemChange}
              options={coverFuneralCostOptions}
              name="customerFirst.wouldLikeToCoverFuneralCost"
              value={formData?.customerFirst?.wouldLikeToCoverFuneralCost}
              type="radio"
            />
            <CustomFormItem
              label="Have you planned for your funeral?"
              onChange={handleAntdFormItemChange}
              options={yesNoOptions}
              name="customerFirst.hasPlanedOwnFuneral"
              value={formData?.customerFirst?.hasPlanedOwnFuneral}
              type="radio"
            />
            <CustomFormItem
              label="Existing Provision available for funeral"
              name="customerFirst.existingFuneralProvisionAvailable"
              placeholder="E.g will be shown here"
              value={formData?.customerFirst?.existingFuneralProvisionAvailable}
              onChange={handleAntdFormItemChange}
              type="number"
              inputPrefixImg={PoundIcon}
              prefixImgHeight={12}
            />
            <CustomFormItem
              label="Projected Shortfall"
              name="customerFirst.projectedShortfall"
              placeholder="E.g will be shown here"
              value={formData?.customerFirst?.projectedShortfall}
              onChange={handleAntdFormItemChange}
              type="number"
              inputPrefixImg={PoundIcon}
              prefixImgHeight={12}
            />
            <CustomFormItem
              label="If relying on sale of property – are beneficiaries able to pay funeral costs before sale of house?"
              onChange={handleAntdFormItemChange}
              options={yesNoSureOptions}
              name="customerFirst.areBeneficiariesAbleToPayFuneralCostBeforeHouseSale"
              value={formData?.customerFirst?.areBeneficiariesAbleToPayFuneralCostBeforeHouseSale}
              type="radio"
            />
            <CustomFormItem
              label="Would you like to make funeral provision one of your Objectives?"
              onChange={handleAntdFormItemChange}
              options={yesNoOptions}
              name="customerFirst.wouldLikeToMakeFuneralProvisionOneOfObjectives"
              value={formData?.customerFirst?.wouldLikeToMakeFuneralProvisionOneOfObjectives}
              type="radio"
            />
            {formData?.customerFirst?.wouldLikeToMakeFuneralProvisionOneOfObjectives && (
              <>
                <CustomFormItem
                  label="How will you pay for a pre-paid funeral plan? *"
                  onChange={handleAntdFormItemChange}
                  options={howToPayPrePaidFuneralPlanOptions}
                  name="customerFirst.howToPayPrePaidFuneralPlan"
                  value={formData?.customerFirst?.howToPayPrePaidFuneralPlan}
                  type="radio"
                  rules={['isRequired']}
                />
                {(formData?.customerFirst?.howToPayPrePaidFuneralPlan === 'partiallyFromLoan' ||
                  formData?.customerFirst?.howToPayPrePaidFuneralPlan === 'fullyFromTheLoan') && (
                  <CustomFormItem
                    label="Amount to be borrowed for funeral plan *"
                    name="customerFirst.amountToBeBorrowedForFuneralPlan"
                    placeholder="Amount to be borrowed for funeral plan *"
                    inputPrefixImg={PoundIcon}
                    prefixImgHeight={12}
                    onChange={handleAntdFormItemChange}
                    type="number"
                    rules={['isRequired']}
                  />
                )}
              </>
            )}
            <CustomFormItem
              label="Would you like to discuss funeral plans now, at another time or have details sent to you?"
              onChange={handleAntdFormItemChange}
              options={funeralDiscussOptions}
              name="customerFirst.funeralPlansDiscussTime"
              value={formData?.customerFirst?.funeralPlansDiscussTime}
              type="radio"
            />
            <CustomFormItem
              label="Any other notes regarding Funeral arrangements"
              placeholder="E.g will be shown here"
              value={formData?.customerFirst?.otherNotesRegardingFuneralArrangements}
              name="customerFirst.otherNotesRegardingFuneralArrangements"
              onChange={handleAntdFormItemChange}
              rows={4}
              type="textarea"
            />
          </>
        )}
        <StyledBreakLine />
        <StyledTitle>Planning for Care</StyledTitle>
        <CustomFormItem
          label="Do you need, or are you already in care? (at home or elsewhere)"
          onChange={handleAntdFormItemChange}
          options={careOptions}
          name="customerFirst.inNeedOrAlreadyInCare"
          value={formData?.customerFirst?.inNeedOrAlreadyInCare}
          type="radio"
        />
        {(formData?.customerFirst?.inNeedOrAlreadyInCare === 'needCare' || formData?.customerFirst?.inNeedOrAlreadyInCare === 'alreadyInCare') && (
          <>
            <CustomFormItem
              label="Has individual(s) undergone an NHS Continuing Care Assessment?"
              onChange={handleAntdFormItemChange}
              options={hasUndergoneNHSCareAssessmentOptions}
              name="customerFirst.hasUndergoneNHSCareAssessment"
              value={formData?.customerFirst?.hasUndergoneNHSCareAssessment}
              type="radio"
            />
            <CustomFormItem
              label="Is individual already in care home?"
              onChange={handleAntdFormItemChange}
              options={yesNoOptions}
              name="customerFirst.isIndividualAlreadyInCareHome"
              value={formData?.customerFirst?.isIndividualAlreadyInCareHome}
              type="radio"
            />
            <CustomSwitch checked={skipRemainingCareQuestions} onChange={skipRemainingCareQuestionsChange} label="Skip remaining Care Questions?" />
            {!skipRemainingCareQuestions && (
              <>
                {formData?.customerFirst?.inNeedOrAlreadyInCare === 'alreadyInCare' && (
                  <>
                    <CustomFormItem
                      label="Care Home Contact Name"
                      name="customerFirst.careHomeContactName"
                      placeholder="Care Home Contact Name"
                      onChange={handleAntdFormItemChange}
                    />
                    <CustomFormItem
                      label="Care Home Contact Name Position"
                      name="customerFirst.careHomeContactNamePosition"
                      placeholder="Care Home Contact Name Position"
                      onChange={handleAntdFormItemChange}
                    />
                    <CustomFormItem
                      label="Care Home Contact Telephone Number"
                      name="customerFirst.careHomeContactNumber"
                      placeholder="Care Home Contact Telephone Number"
                      onChange={handleAntdFormItemChange}
                      rules={['validateTelephoneNumber']}
                    />
                    <CustomFormItem
                      label="Search for Care Home Address"
                      name="customerFirst.careHomeAddress"
                      placeholder="Search for Care Home Address"
                      onChange={handleAntdFormItemChange}
                    />
                    <CustomFormItem
                      label="Type of existing care?"
                      name="customerFirst.typeOfExistingCare"
                      placeholder="Type of existing care?"
                      onChange={handleAntdFormItemChange}
                    />
                    <CustomFormItem
                      label="Existing care - Hours"
                      name="customerFirst.existingCareHours"
                      placeholder="Existing care - Hours"
                      onChange={handleAntdFormItemChange}
                      type="number"
                    />
                    <CustomFormItem
                      label="Existing care - Cost"
                      name="customerFirst.existingCareCost"
                      placeholder="Existing care - Cost"
                      inputPrefixImg={PoundIcon}
                      prefixImgHeight={12}
                      onChange={handleAntdFormItemChange}
                      type="number"
                    />
                    <CustomFormItem
                      label="What type of care is now required"
                      name="customerFirst.requiredCareType"
                      placeholder="What type of care is now required"
                      onChange={handleAntdFormItemChange}
                    />
                    <CustomFormItem
                      label="If living in own home, how long does individual wish to remain in own home?"
                      name="customerFirst.howLongDoesIndividualWishToRemainInOwnHome"
                      placeholder="If living in own home, how long does individual wish to remain in own home?"
                      onChange={handleAntdFormItemChange}
                    />
                    <CustomFormItem
                      label="How likely is this?"
                      name="customerFirst.howLikelyIndividualRemainInOwnHome"
                      placeholder="How likely is this?"
                      onChange={handleAntdFormItemChange}
                    />
                    <CustomFormItem
                      label="How likely are they to move back and forth from home to care?"
                      name="customerFirst.howLikelyIndividualMoveBackAndForthHomeToCare"
                      placeholder="How likely are they to move back and forth from home to care?"
                      onChange={handleAntdFormItemChange}
                    />
                    <CustomFormItem label="Timescales?" name="customerFirst.timescales" placeholder="Timescales?" onChange={handleAntdFormItemChange} />
                    <CustomFormItem
                      label="Has the full financial assessment been undertaken yet?"
                      name="customerFirst.hasFullFinancialAssessmentUndertakenYet"
                      placeholder="Has the full financial assessment been undertaken yet?"
                      onChange={handleAntdFormItemChange}
                    />
                  </>
                )}
                <CustomFormItem
                  label="New Care costs (estimated)"
                  name="customerFirst.estimatedNewCareCosts"
                  placeholder="New Care costs (estimated)"
                  inputPrefixImg={PoundIcon}
                  prefixImgHeight={12}
                  onChange={handleAntdFormItemChange}
                  type="number"
                />
                <CustomFormItem
                  label="New Care costs (actual)"
                  name="customerFirst.actualNewCareCosts"
                  placeholder="New Care costs (actual)"
                  inputPrefixImg={PoundIcon}
                  prefixImgHeight={12}
                  onChange={handleAntdFormItemChange}
                  type="number"
                />
                <CustomFormItem
                  label="When is next review due - please detail date"
                  name="customerFirst.nextReviewDate"
                  placeholder="When is next review due - please detail date"
                  type="date"
                  onChange={handleAntdFormItemChange}
                />
                <CustomFormItem
                  label="Is that accounted in the main Expenditure section?"
                  onChange={handleAntdFormItemChange}
                  options={yesNoOptions}
                  name="customerFirst.isCareExpenditureAccountedInMainExpenditures"
                  value={formData?.customerFirst?.isIndividualAlreadyInCareHome}
                  type="radio"
                />
                <StyledTitle style={{ marginTop: '24px' }}>Property Details (Care)</StyledTitle>
                <CustomFormItem
                  label="Type of Ownership"
                  width={'100%'}
                  placeholder="Type of Ownership"
                  onChange={handleCustomSelectChange}
                  options={ownershipTypeOptions}
                  name="customerFirst.ownershipType"
                  type="select"
                />
                <CustomFormItem
                  label="How is the property to be considered in relation to the care costs"
                  name="customerFirst.considerPropertyInCareCosts"
                  placeholder="How is the property to be considered in relation to the care costs"
                  onChange={handleAntdFormItemChange}
                />
                <CustomFormItem
                  label="Expected / Actual net sale proceeds (if selling for care)"
                  name="customerFirst.netSaleProceedsIfSellingForCare"
                  placeholder="Expected / Actual net sale proceeds (if selling for care)"
                  inputPrefixImg={PoundIcon}
                  prefixImgHeight={12}
                  onChange={handleAntdFormItemChange}
                  type="number"
                />
                <CustomFormItem
                  label="Has this figure been included in the earlier section under deposits held?"
                  onChange={handleAntdFormItemChange}
                  options={yesNoOptions}
                  name="customerFirst.hasNetSaleProceedsBeenIncludedInDepositsHeld"
                  value={formData?.customerFirst?.hasNetSaleProceedsBeenIncludedInDepositsHeld}
                  type="radio"
                />
                <CustomFormItem
                  label="How long is it likely to take to release these funds?"
                  onChange={handleAntdFormItemChange}
                  options={timeToReleaseSaleProceedsOptions}
                  name="customerFirst.timeToReleaseSaleProceeds"
                  value={formData?.customerFirst?.timeToReleaseSaleProceeds}
                  type="radio"
                />
                <CustomFormItem
                  label="How will proceeds from house sale be used?"
                  name="customerFirst.howWillBeSaleProceedsUsed"
                  placeholder="How will proceeds from house sale be used?"
                  onChange={handleAntdFormItemChange}
                />
                <StyledTitle style={{ marginTop: '24px' }}>Care Related Priorities</StyledTitle>
                <StyledDescription>
                  Discuss the following fully with the POA / Customer and then rate the importance of the following features to the customer, rank the top
                  priorities / needs and provide a reason for why these are priorities / needs
                </StyledDescription>
                <CustomFormItem
                  label="Guaranteed income for life"
                  onChange={handleAntdFormItemChange}
                  options={importanceOptions}
                  name="customerFirst.guaranteedIncomeForLife"
                  value={formData?.customerFirst?.guaranteedIncomeForLife}
                  type="radio"
                />
                <CustomFormItem
                  label="Not taking any investment risk in funding for the care fees / costs"
                  onChange={handleAntdFormItemChange}
                  options={importanceOptions}
                  name="customerFirst.notTakeInvestmentRiskInFundingForTheCareFeesCosts"
                  value={formData?.customerFirst?.notTakeInvestmentRiskInFundingForTheCareFeesCosts}
                  type="radio"
                />
                <CustomFormItem
                  label="Income is more important than capital growth"
                  onChange={handleAntdFormItemChange}
                  options={importanceOptions}
                  name="customerFirst.incomeIsMoreImportantThanCapitalGrowth"
                  value={formData?.customerFirst?.incomeIsMoreImportantThanCapitalGrowth}
                  type="radio"
                />
                <CustomFormItem
                  label="Leaving an inheritance to my beneficiaries"
                  onChange={handleAntdFormItemChange}
                  options={importanceOptions}
                  name="customerFirst.leavingInheritanceToBeneficiaries"
                  value={formData?.customerFirst?.leavingInheritanceToBeneficiaries}
                  type="radio"
                />
                <CustomFormItem
                  label="Protecting against any increase in care support costs"
                  onChange={handleAntdFormItemChange}
                  options={importanceOptions}
                  name="customerFirst.protectingCareCostsIncrease"
                  value={formData?.customerFirst?.protectingCareCostsIncrease}
                  type="radio"
                />
                <CustomFormItem
                  label="Having the ability to increase income in the future as a result of increased care needs"
                  onChange={handleAntdFormItemChange}
                  options={importanceOptions}
                  name="customerFirst.abilityToIncreaseIncome"
                  value={formData?.customerFirst?.abilityToIncreaseIncome}
                  type="radio"
                />
                <CustomFormItem
                  label="Protecting capital / income against inflation"
                  onChange={handleAntdFormItemChange}
                  options={importanceOptions}
                  name="customerFirst.protectingCapitalFromInflation"
                  value={formData?.customerFirst?.protectingCapitalFromInflation}
                  type="radio"
                />
                <CustomFormItem
                  label="Peace of Mind that there are unlikely to be any unexpected care bills in future"
                  onChange={handleAntdFormItemChange}
                  options={importanceOptions}
                  name="customerFirst.careBillCertainty"
                  value={formData?.customerFirst?.careBillCertainty}
                  type="radio"
                />
                <CustomFormItem
                  label="Other objectives or areas that the POA / Customer believes are important and need to be considered (state priority also)"
                  placeholder="E.g will be shown here"
                  value={formData?.customerFirst?.otherCustomerOrPOAPriorities}
                  name="customerFirst.otherCustomerOrPOAPriorities"
                  onChange={handleAntdFormItemChange}
                  rows={4}
                  type="textarea"
                />
                <StyledTitle style={{ marginTop: '24px' }}>Care Solutions</StyledTitle>
                <StyledDescription>
                  If at this point, an Immediate Needs Annuity is likely to be suitable and you are able to discuss the options and features needed to meet the
                  needs and objectives please capture the following;
                </StyledDescription>
                <CustomFormItem
                  label="Amount of benefit to be paid per month"
                  name="customerFirst.amountOfBenefitToBePaidPerMonth"
                  placeholder="Amount of benefit to be paid per month"
                  inputPrefixImg={PoundIcon}
                  prefixImgHeight={12}
                  onChange={handleAntdFormItemChange}
                  type="number"
                />
                <CustomFormItem
                  label="Lump Sum amount available to secure benefits (including any advice fees)"
                  name="customerFirst.benefitsLumpSum"
                  placeholder="Lump Sum amount available to secure benefits (including any advice fees)"
                  inputPrefixImg={PoundIcon}
                  prefixImgHeight={12}
                  onChange={handleAntdFormItemChange}
                  type="number"
                />
                <CustomFormItem
                  label="Escalation"
                  onChange={handleAntdFormItemChange}
                  options={escalationOptions}
                  name="customerFirst.escalation"
                  value={formData?.customerFirst?.escalation}
                  type="radio"
                />
                <CustomFormItem
                  label="Increase if applicable"
                  name="customerFirst.escalationPercentage"
                  placeholder="Increase if applicable"
                  onChange={handleAntdFormItemChange}
                  type="number"
                />
                <CustomFormItem
                  label="Any additional death benefits?"
                  width={'100%'}
                  placeholder="Any additional death benefits?"
                  onChange={handleCustomSelectChange}
                  options={additionalDeathBenefitsOptions}
                  name="customerFirst.additionalDeathBenefits"
                  type="select"
                />
                <CustomFormItem
                  label="Capital protection if applicable"
                  name="customerFirst.capitalProtectionPercentage"
                  placeholder="Capital protection if applicable"
                  onChange={handleAntdFormItemChange}
                  type="number"
                />
                <CustomFormItem
                  label="Deferred period"
                  onChange={handleAntdFormItemChange}
                  options={deferredPeriodOptions}
                  name="customerFirst.deferredPeriod"
                  value={formData?.customerFirst?.deferredPeriod}
                  type="radio"
                />
                <CustomFormItem
                  label="What will happen if the care shortfall cannot be met in full?"
                  name="customerFirst.partlyMetCareShortfallImpact"
                  placeholder="What will happen if the care shortfall cannot be met in full?"
                  onChange={handleAntdFormItemChange}
                />
                <StyledBreakLine />
                <StyledTitle style={{ marginTop: '24px' }}>Power Of Attorney / Customer Opinion</StyledTitle>
                <CustomFormItem
                  label="How do you feel about future care requirements beyond what is currently being provided?"
                  placeholder="E.g will be shown here"
                  value={formData?.customerFirst?.futureCareSentiment}
                  name="customerFirst.futureCareSentiment"
                  onChange={handleAntdFormItemChange}
                  rows={4}
                  type="textarea"
                />
                <CustomFormItem
                  label="Is the need for care likely to increase? - Provide detail in notes section"
                  onChange={handleAntdFormItemChange}
                  options={yesNoOptions}
                  name="customerFirst.isNeedForCareLikelyToIncrease"
                  value={formData?.customerFirst?.isNeedForCareLikelyToIncrease}
                  type="radio"
                />
              </>
            )}
          </>
        )}
        {formData?.customerFirst?.inNeedOrAlreadyInCare === 'noImmediateNeedForCare' && (
          <CustomFormItem
            label="Would you like to make some provision to ensure you have the care you want?"
            onChange={handleAntdFormItemChange}
            options={yesNoOptions}
            name="customerFirst.makeSomeProvisionToEnsureForCare"
            value={formData?.customerFirst?.makeSomeProvisionToEnsureForCare}
            type="radio"
          />
        )}
        {customers?.customerSecond?.firstName && (
          <>
            <StyledBreakLine />
            <StyledTitle>Estate Planning for {customers?.customerSecond?.firstName}</StyledTitle>
            <FormTable
              columns={disclosedBeneficiariesTableColumns}
              dataSource={disclosedBeneficiariesDataSource}
              title="Your Disclosed Beneficiaries"
              checkboxColumn={false}
              tableContainerBgColor="#fce4ec"
            />
            <StyledBreakLine />
            <CustomFormItem
              label="Is the customer taking specialist advice regarding Estate Planning? *"
              width={'100%'}
              placeholder="Is the customer taking specialist advice regarding Estate Planning? *"
              onChange={handleCustomSelectChange}
              options={specialistAdviceOptions}
              name="customerSecond.isCustomerTakingSpecialistAdvice"
              type="select"
              rules={['isRequired']}
            />
            <StyledBreakLine />
            <StyledTitle>Your Will</StyledTitle>
            <CustomSwitch
              checked={formData?.customerSecond?.skipWillQuestions}
              onChange={(checked: boolean) => handleCustomSwitch({ checked, field: 'customerSecond.skipWillQuestions' })}
              label="Skip Will Questions?"
              alignSelf="flex-start"
            />
            {!formData?.customerSecond?.skipWillQuestions && (
              <>
                <CustomFormItem
                  label="Have you made a Will?"
                  onChange={handleAntdFormItemChange}
                  options={yesNoOptions}
                  name="customerSecond.hasWill"
                  value={formData?.customerSecond?.hasWill}
                  type="radio"
                />
                {formData?.customerSecond?.hasWill && (
                  <>
                    <CustomFormItem
                      label="Does your Will need to be updated?"
                      onChange={handleAntdFormItemChange}
                      options={yesNoOptions}
                      name="customerSecond.willNeedsUpdate"
                      value={formData?.customerSecond?.willNeedsUpdate}
                      type="radio"
                    />
                    <CustomFormItem
                      label="When did you last update your Will?"
                      name="customerSecond.willLastUpdatedDate"
                      placeholder="DD/MM/YYYY"
                      type="date"
                      onChange={handleAntdFormItemChange}
                    />
                  </>
                )}
                <CustomFormItem
                  label="Would you like your Family Will to be one of your Objectives? *"
                  onChange={handleAntdFormItemChange}
                  options={yesNoOptions}
                  name="customerSecond.wouldLikeFamilyWillToBeOneOfObjectives"
                  value={formData?.customerSecond?.wouldLikeFamilyWillToBeOneOfObjectives}
                  type="radio"
                  rules={['isRequired']}
                />
                {formData?.customerSecond?.wouldLikeFamilyWillToBeOneOfObjectives && (
                  <>
                    <CustomFormItem
                      label="Amount required for Will"
                      name="customerSecond.amountOfWill"
                      placeholder="Amount required for Will"
                      inputPrefixImg={PoundIcon}
                      prefixImgHeight={12}
                      onChange={handleAntdFormItemChange}
                      type="number"
                    />
                    <CustomFormItem
                      label="Do you want to add above amount to loan?"
                      onChange={handleAntdFormItemChange}
                      options={yesNoSomeOptions}
                      name="customerSecond.addAmountOfWillToLoan"
                      value={formData?.customerSecond?.addAmountOfWillToLoan}
                      type="radio"
                    />
                    {formData?.customerSecond?.addAmountOfWillToLoan === 'someOfIt' && (
                      <CustomFormItem
                        label="How much do you need to add to loan for the Will?"
                        name="customerSecond.howMuchAmountOfWillAddToLoan"
                        placeholder="How much do you need to add to loan for the Will?"
                        inputPrefixImg={PoundIcon}
                        prefixImgHeight={12}
                        onChange={handleAntdFormItemChange}
                        type="number"
                      />
                    )}
                  </>
                )}
                <CustomFormItem
                  label="Any other details about your conversation regarding Wills"
                  placeholder="E.g will be shown here"
                  value={formData?.customerSecond?.conversationDetailsAboutWill}
                  name="customerSecond.conversationDetailsAboutWill"
                  onChange={handleAntdFormItemChange}
                  rows={4}
                  type="textarea"
                />
              </>
            )}
            <StyledBreakLine />
            <StyledTitle>Power of Attorney</StyledTitle>
            <CustomSwitch
              checked={formData?.customerSecond?.skipPOAQuestions}
              onChange={(checked: boolean) => handleCustomSwitch({ checked, field: 'customerSecond.skipPOAQuestions' })}
              label="Skip Power of Attorney Questions?"
              alignSelf="flex-start"
            />
            {!formData?.customerSecond?.skipPOAQuestions && (
              <>
                <CustomFormItem
                  label="Who would make decisions on your behalf if you were unable to due to mental or serious illness?"
                  width={'100%'}
                  placeholder="Who would make decisions on your behalf if you were unable to due to mental or serious illness?"
                  onChange={handleCustomSelectChange}
                  options={substituteDecisionMakerOptionsForCustomerSecond}
                  name="customerSecond.substituteDecisionMaker"
                  type="select"
                />
                <CustomFormItem
                  label="Which Power of Attorney do you have (if applicable)?"
                  width={'100%'}
                  placeholder="Which Power of Attorney do you have (if applicable)?"
                  onChange={handleCustomSelectChange}
                  options={powerOfAttorneyOptions}
                  name="customerSecond.powerOfAttorneyType"
                  type="select"
                />
                <CustomFormItem
                  label="Please provide details i.e. type of POA, named attorney/s, if attorneys act jointly/severally, if registered"
                  placeholder="E.g will be shown here"
                  value={formData?.customerSecond?.detailsAboutAttorney}
                  name="customerSecond.detailsAboutAttorney"
                  onChange={handleAntdFormItemChange}
                  rows={4}
                  type="textarea"
                />
                <CustomFormItem
                  label="Has a copy of the POA been taken for the file? *"
                  onChange={handleAntdFormItemChange}
                  options={yesNoOptions}
                  name="customerSecond.hasCopyOfPOA"
                  value={formData?.customerSecond?.hasCopyOfPOA}
                  type="radio"
                  rules={['isRequired']}
                />
                <CustomFormItem
                  label="Would you like to make a Power of Attorney one of your Objectives? *"
                  onChange={handleAntdFormItemChange}
                  options={yesNoOptions}
                  name="customerSecond.wouldLikePOAToBeOneOfObjectives"
                  value={formData?.customerSecond?.wouldLikePOAToBeOneOfObjectives}
                  type="radio"
                  rules={['isRequired']}
                />
                {formData?.customerSecond?.wouldLikePOAToBeOneOfObjectives && (
                  <>
                    <CustomFormItem
                      label="Amount required for Power of Attorney"
                      name="customerSecond.amountForPOA"
                      placeholder="Amount required for Power of Attorney"
                      inputPrefixImg={PoundIcon}
                      prefixImgHeight={12}
                      onChange={handleAntdFormItemChange}
                      type="number"
                    />
                    <CustomFormItem
                      label="Do you want to add above amount to loan?"
                      onChange={handleAntdFormItemChange}
                      options={yesNoSomeOptions}
                      name="customerSecond.addAmountForPOAToLoan"
                      value={formData?.customerSecond?.addAmountForPOAToLoan}
                      type="radio"
                    />
                    {formData?.customerSecond?.addAmountForPOAToLoan === 'someOfIt' && (
                      <CustomFormItem
                        label="How much do you need to add to loan for the Power of Attorney?"
                        name="customerSecond.howMuchAmountForPOAAddToLoan"
                        placeholder="How much do you need to add to loan for the Power of Attorney?"
                        inputPrefixImg={PoundIcon}
                        prefixImgHeight={12}
                        onChange={handleAntdFormItemChange}
                        type="number"
                      />
                    )}
                  </>
                )}
                <CustomFormItem
                  label="Customer's opinion/decision regarding a Power of Attorney"
                  placeholder="E.g will be shown here"
                  value={formData?.customerSecond?.customerOpinionRegardingPOA}
                  name="customerSecond.customerOpinionRegardingPOA"
                  onChange={handleAntdFormItemChange}
                  rows={4}
                  type="textarea"
                />
              </>
            )}
            <StyledBreakLine />
            <StyledTitle>Your Funeral</StyledTitle>
            <CustomSwitch
              checked={formData?.customerSecond?.skipFuneralQuestions}
              onChange={(checked: boolean) => handleCustomSwitch({ checked, field: 'customerSecond.skipFuneralQuestions' })}
              label="Skip Funeral Questions?"
              alignSelf="flex-start"
            />
            {!formData?.customerSecond?.skipFuneralQuestions && (
              <>
                <CustomFormItem
                  label="Would you like to cover the cost of your funeral for your family when you die?"
                  onChange={handleAntdFormItemChange}
                  options={coverFuneralCostOptions}
                  name="customerSecond.wouldLikeToCoverFuneralCost"
                  value={formData?.customerSecond?.wouldLikeToCoverFuneralCost}
                  type="radio"
                />
                <CustomFormItem
                  label="Have you planned for your funeral?"
                  onChange={handleAntdFormItemChange}
                  options={yesNoOptions}
                  name="customerSecond.hasPlanedOwnFuneral"
                  value={formData?.customerSecond?.hasPlanedOwnFuneral}
                  type="radio"
                  inputPrefixImg={PoundIcon}
                  prefixImgHeight={12}
                />
                <CustomFormItem
                  label="Existing Provision available for funeral"
                  name="customerSecond.existingFuneralProvisionAvailable"
                  placeholder="E.g will be shown here"
                  value={formData?.customerSecond?.existingFuneralProvisionAvailable}
                  onChange={handleAntdFormItemChange}
                  type="number"
                  inputPrefixImg={PoundIcon}
                  prefixImgHeight={12}
                />
                <CustomFormItem
                  label="Projected Shortfall"
                  name="customerSecond.projectedShortfall"
                  placeholder="E.g will be shown here"
                  value={formData?.customerSecond?.projectedShortfall}
                  onChange={handleAntdFormItemChange}
                  type="number"
                />
                <CustomFormItem
                  label="If relying on sale of property – are beneficiaries able to pay funeral costs before sale of house?"
                  onChange={handleAntdFormItemChange}
                  options={yesNoSureOptions}
                  name="customerSecond.areBeneficiariesAbleToPayFuneralCostBeforeHouseSale"
                  value={formData?.customerSecond?.areBeneficiariesAbleToPayFuneralCostBeforeHouseSale}
                  type="radio"
                />
                <CustomFormItem
                  label="Would you like to make funeral provision one of your Objectives?"
                  onChange={handleAntdFormItemChange}
                  options={yesNoOptions}
                  name="customerSecond.wouldLikeToMakeFuneralProvisionOneOfObjectives"
                  value={formData?.customerSecond?.wouldLikeToMakeFuneralProvisionOneOfObjectives}
                  type="radio"
                />
                {formData?.customerSecond?.wouldLikeToMakeFuneralProvisionOneOfObjectives && (
                  <>
                    <CustomFormItem
                      label="How will you pay for a pre-paid funeral plan? *"
                      onChange={handleAntdFormItemChange}
                      options={howToPayPrePaidFuneralPlanOptions}
                      name="customerSecond.howToPayPrePaidFuneralPlan"
                      value={formData?.customerSecond?.howToPayPrePaidFuneralPlan}
                      type="radio"
                      rules={['isRequired']}
                    />
                    {(formData?.customerSecond?.howToPayPrePaidFuneralPlan === 'partiallyFromLoan' ||
                      formData?.customerSecond?.howToPayPrePaidFuneralPlan === 'fullyFromTheLoan') && (
                      <CustomFormItem
                        label="Amount to be borrowed for funeral plan *"
                        name="customerSecond.amountToBeBorrowedForFuneralPlan"
                        placeholder="Amount to be borrowed for funeral plan *"
                        inputPrefixImg={PoundIcon}
                        prefixImgHeight={12}
                        onChange={handleAntdFormItemChange}
                        type="number"
                        rules={['isRequired']}
                      />
                    )}
                  </>
                )}
                <CustomFormItem
                  label="Would you like to discuss funeral plans now, at another time or have details sent to you?"
                  onChange={handleAntdFormItemChange}
                  options={funeralDiscussOptions}
                  name="customerSecond.funeralPlansDiscussTime"
                  value={formData?.customerSecond?.funeralPlansDiscussTime}
                  type="radio"
                />
                <CustomFormItem
                  label="Any other notes regarding Funeral arrangements"
                  placeholder="E.g will be shown here"
                  value={formData?.customerSecond?.otherNotesRegardingFuneralArrangements}
                  name="customerSecond.otherNotesRegardingFuneralArrangements"
                  onChange={handleAntdFormItemChange}
                  rows={4}
                  type="textarea"
                />
              </>
            )}
            <StyledBreakLine />
            <StyledTitle>Planning for Care</StyledTitle>
            <CustomFormItem
              label="Do you need, or are you already in care? (at home or elsewhere)"
              onChange={handleAntdFormItemChange}
              options={careOptions}
              name="customerSecond.inNeedOrAlreadyInCare"
              value={formData?.customerSecond?.inNeedOrAlreadyInCare}
              type="radio"
            />
            {(formData?.customerSecond?.inNeedOrAlreadyInCare === 'needCare' || formData?.customerSecond?.inNeedOrAlreadyInCare === 'alreadyInCare') && (
              <>
                <CustomFormItem
                  label="Has individual(s) undergone an NHS Continuing Care Assessment?"
                  onChange={handleAntdFormItemChange}
                  options={hasUndergoneNHSCareAssessmentOptions}
                  name="customerSecond.hasUndergoneNHSCareAssessment"
                  value={formData?.customerSecond?.hasUndergoneNHSCareAssessment}
                  type="radio"
                />
                <CustomFormItem
                  label="Is individual already in care home?"
                  onChange={handleAntdFormItemChange}
                  options={yesNoOptions}
                  name="customerSecond.isIndividualAlreadyInCareHome"
                  value={formData?.customerSecond?.isIndividualAlreadyInCareHome}
                  type="radio"
                />
                <CustomSwitch checked={skipRemainingCareQuestions} onChange={skipRemainingCareQuestionsChange} label="Skip remaining Care Questions?" />
                {!skipRemainingCareQuestions && (
                  <>
                    {formData?.customerSecond?.inNeedOrAlreadyInCare === 'alreadyInCare' && (
                      <>
                        <CustomFormItem
                          label="Care Home Contact Name"
                          name="customerSecond.careHomeContactName"
                          placeholder="Care Home Contact Name"
                          onChange={handleAntdFormItemChange}
                        />
                        <CustomFormItem
                          label="Care Home Contact Name Position"
                          name="customerSecond.careHomeContactNamePosition"
                          placeholder="Care Home Contact Name Position"
                          onChange={handleAntdFormItemChange}
                        />
                        <CustomFormItem
                          label="Care Home Contact Telephone Number"
                          name="customerSecond.careHomeContactNumber"
                          placeholder="Care Home Contact Telephone Number"
                          onChange={handleAntdFormItemChange}
                          rules={['validateTelephoneNumber']}
                        />
                        <CustomFormItem
                          label="Search for Care Home Address"
                          name="customerSecond.careHomeAddress"
                          placeholder="Search for Care Home Address"
                          onChange={handleAntdFormItemChange}
                        />
                        <CustomFormItem
                          label="Type of existing care?"
                          name="customerSecond.typeOfExistingCare"
                          placeholder="Type of existing care?"
                          onChange={handleAntdFormItemChange}
                        />
                        <CustomFormItem
                          label="Existing care - Hours"
                          name="customerSecond.existingCareHours"
                          placeholder="Existing care - Hours"
                          onChange={handleAntdFormItemChange}
                          type="number"
                        />
                        <CustomFormItem
                          label="Existing care - Cost"
                          name="customerSecond.existingCareCost"
                          placeholder="Existing care - Cost"
                          inputPrefixImg={PoundIcon}
                          prefixImgHeight={12}
                          onChange={handleAntdFormItemChange}
                          type="number"
                        />
                        <CustomFormItem
                          label="What type of care is now required"
                          name="customerSecond.requiredCareType"
                          placeholder="What type of care is now required"
                          onChange={handleAntdFormItemChange}
                        />
                        <CustomFormItem
                          label="If living in own home, how long does individual wish to remain in own home?"
                          name="customerSecond.howLongDoesIndividualWishToRemainInOwnHome"
                          placeholder="If living in own home, how long does individual wish to remain in own home?"
                          onChange={handleAntdFormItemChange}
                        />
                        <CustomFormItem
                          label="How likely is this?"
                          name="customerSecond.howLikelyIndividualRemainInOwnHome"
                          placeholder="How likely is this?"
                          onChange={handleAntdFormItemChange}
                        />
                        <CustomFormItem
                          label="How likely are they to move back and forth from home to care?"
                          name="customerSecond.howLikelyIndividualMoveBackAndForthHomeToCare"
                          placeholder="How likely are they to move back and forth from home to care?"
                          onChange={handleAntdFormItemChange}
                        />
                        <CustomFormItem label="Timescales?" name="customerSecond.timescales" placeholder="Timescales?" onChange={handleAntdFormItemChange} />
                        <CustomFormItem
                          label="Has the full financial assessment been undertaken yet?"
                          name="customerSecond.hasFullFinancialAssessmentUndertakenYet"
                          placeholder="Has the full financial assessment been undertaken yet?"
                          onChange={handleAntdFormItemChange}
                        />
                      </>
                    )}
                    <CustomFormItem
                      label="New Care costs (estimated)"
                      name="customerSecond.estimatedNewCareCosts"
                      placeholder="New Care costs (estimated)"
                      inputPrefixImg={PoundIcon}
                      prefixImgHeight={12}
                      onChange={handleAntdFormItemChange}
                      type="number"
                    />
                    <CustomFormItem
                      label="New Care costs (actual)"
                      name="customerSecond.actualNewCareCosts"
                      placeholder="New Care costs (actual)"
                      inputPrefixImg={PoundIcon}
                      prefixImgHeight={12}
                      onChange={handleAntdFormItemChange}
                      type="number"
                    />
                    <CustomFormItem
                      label="When is next review due - please detail date"
                      name="customerSecond.nextReviewDate"
                      placeholder="When is next review due - please detail date"
                      type="date"
                      onChange={handleAntdFormItemChange}
                    />
                    <CustomFormItem
                      label="Is that accounted in the main Expenditure section?"
                      onChange={handleAntdFormItemChange}
                      options={yesNoOptions}
                      name="customerSecond.isCareExpenditureAccountedInMainExpenditures"
                      value={formData?.customerSecond?.isIndividualAlreadyInCareHome}
                      type="radio"
                    />
                    <StyledTitle style={{ marginTop: '24px' }}>Property Details (Care)</StyledTitle>
                    <CustomFormItem
                      label="Type of Ownership"
                      width={'100%'}
                      placeholder="Type of Ownership"
                      onChange={handleCustomSelectChange}
                      options={ownershipTypeOptions}
                      name="customerSecond.ownershipType"
                      type="select"
                    />
                    <CustomFormItem
                      label="How is the property to be considered in relation to the care costs"
                      name="customerSecond.considerPropertyInCareCosts"
                      placeholder="How is the property to be considered in relation to the care costs"
                      onChange={handleAntdFormItemChange}
                    />
                    <CustomFormItem
                      label="Expected / Actual net sale proceeds (if selling for care)"
                      name="customerSecond.netSaleProceedsIfSellingForCare"
                      placeholder="Expected / Actual net sale proceeds (if selling for care)"
                      inputPrefixImg={PoundIcon}
                      prefixImgHeight={12}
                      onChange={handleAntdFormItemChange}
                      type="number"
                    />
                    <CustomFormItem
                      label="Has this figure been included in the earlier section under deposits held?"
                      onChange={handleAntdFormItemChange}
                      options={yesNoOptions}
                      name="customerSecond.hasNetSaleProceedsBeenIncludedInDepositsHeld"
                      value={formData?.customerSecond?.hasNetSaleProceedsBeenIncludedInDepositsHeld}
                      type="radio"
                    />
                    <CustomFormItem
                      label="How long is it likely to take to release these funds?"
                      onChange={handleAntdFormItemChange}
                      options={timeToReleaseSaleProceedsOptions}
                      name="customerSecond.timeToReleaseSaleProceeds"
                      value={formData?.customerSecond?.timeToReleaseSaleProceeds}
                      type="radio"
                    />
                    <CustomFormItem
                      label="How will proceeds from house sale be used?"
                      name="customerSecond.howWillBeSaleProceedsUsed"
                      placeholder="How will proceeds from house sale be used?"
                      onChange={handleAntdFormItemChange}
                    />
                    <StyledTitle style={{ marginTop: '24px' }}>Care Related Priorities</StyledTitle>
                    <StyledDescription>
                      Discuss the following fully with the POA / Customer and then rate the importance of the following features to the customer, rank the top
                      priorities / needs and provide a reason for why these are priorities / needs
                    </StyledDescription>
                    <CustomFormItem
                      label="Guaranteed income for life"
                      onChange={handleAntdFormItemChange}
                      options={importanceOptions}
                      name="customerSecond.guaranteedIncomeForLife"
                      value={formData?.customerSecond?.guaranteedIncomeForLife}
                      type="radio"
                    />
                    <CustomFormItem
                      label="Not taking any investment risk in funding for the care fees / costs"
                      onChange={handleAntdFormItemChange}
                      options={importanceOptions}
                      name="customerSecond.notTakeInvestmentRiskInFundingForTheCareFeesCosts"
                      value={formData?.customerSecond?.notTakeInvestmentRiskInFundingForTheCareFeesCosts}
                      type="radio"
                    />
                    <CustomFormItem
                      label="Income is more important than capital growth"
                      onChange={handleAntdFormItemChange}
                      options={importanceOptions}
                      name="customerSecond.incomeIsMoreImportantThanCapitalGrowth"
                      value={formData?.customerSecond?.incomeIsMoreImportantThanCapitalGrowth}
                      type="radio"
                    />
                    <CustomFormItem
                      label="Leaving an inheritance to my beneficiaries"
                      onChange={handleAntdFormItemChange}
                      options={importanceOptions}
                      name="customerSecond.leavingInheritanceToBeneficiaries"
                      value={formData?.customerSecond?.leavingInheritanceToBeneficiaries}
                      type="radio"
                    />
                    <CustomFormItem
                      label="Protecting against any increase in care support costs"
                      onChange={handleAntdFormItemChange}
                      options={importanceOptions}
                      name="customerSecond.protectingCareCostsIncrease"
                      value={formData?.customerSecond?.protectingCareCostsIncrease}
                      type="radio"
                    />
                    <CustomFormItem
                      label="Having the ability to increase income in the future as a result of increased care needs"
                      onChange={handleAntdFormItemChange}
                      options={importanceOptions}
                      name="customerSecond.abilityToIncreaseIncome"
                      value={formData?.customerSecond?.abilityToIncreaseIncome}
                      type="radio"
                    />
                    <CustomFormItem
                      label="Protecting capital / income against inflation"
                      onChange={handleAntdFormItemChange}
                      options={importanceOptions}
                      name="customerSecond.protectingCapitalFromInflation"
                      value={formData?.customerSecond?.protectingCapitalFromInflation}
                      type="radio"
                    />
                    <CustomFormItem
                      label="Peace of Mind that there are unlikely to be any unexpected care bills in future"
                      onChange={handleAntdFormItemChange}
                      options={importanceOptions}
                      name="customerSecond.careBillCertainty"
                      value={formData?.customerSecond?.careBillCertainty}
                      type="radio"
                    />
                    <CustomFormItem
                      label="Other objectives or areas that the POA / Customer believes are important and need to be considered (state priority also)"
                      placeholder="E.g will be shown here"
                      value={formData?.customerSecond?.otherCustomerOrPOAPriorities}
                      name="customerSecond.otherCustomerOrPOAPriorities"
                      onChange={handleAntdFormItemChange}
                      rows={4}
                      type="textarea"
                    />
                    <StyledTitle style={{ marginTop: '24px' }}>Care Solutions</StyledTitle>
                    <StyledDescription>
                      If at this point, an Immediate Needs Annuity is likely to be suitable and you are able to discuss the options and features needed to meet
                      the needs and objectives please capture the following;
                    </StyledDescription>
                    <CustomFormItem
                      label="Amount of benefit to be paid per month"
                      name="customerSecond.amountOfBenefitToBePaidPerMonth"
                      placeholder="Amount of benefit to be paid per month"
                      inputPrefixImg={PoundIcon}
                      prefixImgHeight={12}
                      onChange={handleAntdFormItemChange}
                      type="number"
                    />
                    <CustomFormItem
                      label="Lump Sum amount available to secure benefits (including any advice fees)"
                      name="customerSecond.benefitsLumpSum"
                      placeholder="Lump Sum amount available to secure benefits (including any advice fees)"
                      inputPrefixImg={PoundIcon}
                      prefixImgHeight={12}
                      onChange={handleAntdFormItemChange}
                      type="number"
                    />
                    <CustomFormItem
                      label="Escalation"
                      onChange={handleAntdFormItemChange}
                      options={escalationOptions}
                      name="customerSecond.escalation"
                      value={formData?.customerSecond?.escalation}
                      type="radio"
                    />
                    <CustomFormItem
                      label="Increase if applicable"
                      name="customerSecond.escalationPercentage"
                      placeholder="Increase if applicable"
                      onChange={handleAntdFormItemChange}
                      type="number"
                    />
                    <CustomFormItem
                      label="Any additional death benefits?"
                      width={'100%'}
                      placeholder="Any additional death benefits?"
                      onChange={handleCustomSelectChange}
                      options={additionalDeathBenefitsOptions}
                      name="customerSecond.additionalDeathBenefits"
                      type="select"
                    />
                    <CustomFormItem
                      label="Capital protection if applicable"
                      name="customerSecond.capitalProtectionPercentage"
                      placeholder="Capital protection if applicable"
                      onChange={handleAntdFormItemChange}
                      type="number"
                    />
                    <CustomFormItem
                      label="Deferred period"
                      onChange={handleAntdFormItemChange}
                      options={deferredPeriodOptions}
                      name="customerSecond.deferredPeriod"
                      value={formData?.customerSecond?.deferredPeriod}
                      type="radio"
                    />
                    <CustomFormItem
                      label="What will happen if the care shortfall cannot be met in full?"
                      name="customerSecond.partlyMetCareShortfallImpact"
                      placeholder="What will happen if the care shortfall cannot be met in full?"
                      onChange={handleAntdFormItemChange}
                    />
                    <StyledBreakLine />
                    <StyledTitle style={{ marginTop: '24px' }}>Power Of Attorney / Customer Opinion</StyledTitle>
                    <CustomFormItem
                      label="How do you feel about future care requirements beyond what is currently being provided?"
                      placeholder="E.g will be shown here"
                      value={formData?.customerSecond?.futureCareSentiment}
                      name="customerSecond.futureCareSentiment"
                      onChange={handleAntdFormItemChange}
                      rows={4}
                      type="textarea"
                    />
                    <CustomFormItem
                      label="Is the need for care likely to increase? - Provide detail in notes section"
                      onChange={handleAntdFormItemChange}
                      options={yesNoOptions}
                      name="customerSecond.isNeedForCareLikelyToIncrease"
                      value={formData?.customerSecond?.isNeedForCareLikelyToIncrease}
                      type="radio"
                    />
                  </>
                )}
              </>
            )}
            {formData?.customerSecond?.inNeedOrAlreadyInCare === 'noImmediateNeedForCare' && (
              <CustomFormItem
                label="Would you like to make some provision to ensure you have the care you want?"
                onChange={handleAntdFormItemChange}
                options={yesNoOptions}
                name="customerSecond.makeSomeProvisionToEnsureForCare"
                value={formData?.customerSecond?.makeSomeProvisionToEnsureForCare}
                type="radio"
              />
            )}
          </>
        )}

        <CustomFooter loading={loading} setSaveType={setSaveType} />
      </Form>
    </StyledContainer>
  )
}

export default EstatePlanning
