import { useEffect, useState } from 'react'
import { message } from 'antd'
import { TypeRowSelection } from '@inovua/reactdatagrid-community/types'

import { commonHelper } from 'helpers'
import { useGetAssetsInfo, useGetCustomers, useGetOtherAssets } from 'services/clients/clients.service'

import useGeneral from './useGeneral'
import { commonUtils, eventManager } from 'utils'
import { addEditFormMutation, deleteOtherAssetsMutation } from 'gql/client'
import { useCustomMutation } from 'services/shared/mutation'
import { TAddEditFormMutationPayload, DeleteWithIdNumberAnd_IdsPayload } from 'services/shared/mutation.payload'
import { getCustomersOptions } from 'utils/getCustomersOptions'
import { FORMS_ENUM } from 'enum/form.enum'

const useAssets = () => {
  const deleteOtherAssets = useCustomMutation<DeleteWithIdNumberAnd_IdsPayload>({ mutation: deleteOtherAssetsMutation })
  const addEditAssets = useCustomMutation<TAddEditFormMutationPayload>({ mutation: addEditFormMutation, refetchQueries: ['getClientFormsProgress'] })
  const urlParams = new URLSearchParams(window.location.search)
  const idNumber = Number(urlParams.get('idNumber'))
  const { data, loading: formInitialDataLoading, refetch } = useGetAssetsInfo(idNumber)
  const { otherAssets } = useGetOtherAssets(idNumber)
  const { data: customersData } = useGetCustomers(idNumber)
  const [loading, setLoading] = useState(formInitialDataLoading)
  const [formData, setFormData] = useState({
    hasOtherAssets: data?.hasOtherAssets,
    canUtilizeOtherAssets: data?.canUtilizeOtherAssets,
    howMuchWantsToUtilize: data?.howMuchWantsToUtilize,
    reasonNotUtilizingOtherAssets: data?.reasonNotUtilizingOtherAssets,
    hasCashInDeposit: data?.hasCashInDeposit,
    howMuchCashHasInDeposit: data?.howMuchCashHasInDeposit,
    howNonInsuredEmergenciesAreFunded: data?.howNonInsuredEmergenciesAreFunded,
  })

  const customersOptions = getCustomersOptions(customersData, true)

  useEffect(() => {
    window.scroll({ top: 0, behavior: 'smooth' })
  }, [loading])

  useEffect(() => {
    setLoading(formInitialDataLoading)
    const newData = commonUtils.removeTypeNameFromObject(data)
    delete newData._id
    setFormData({ ...newData })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formInitialDataLoading])

  const handleAntdFormItemChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const parsedValue = commonUtils.parseValue(e.target?.value, e.target?.name)
    setFormData((prevState) => ({
      ...prevState,
      [e.target.name]: parsedValue,
    }))
  }

  const handleSubmit = async () => {
    const { errors } = await addEditAssets({
      idNumber,
      formName: FORMS_ENUM.assets,
      values: {
        assets: {
          ...formData,
          howMuchWantsToUtilize: Number(formData.howMuchWantsToUtilize),
          howMuchCashHasInDeposit: Number(formData.howMuchCashHasInDeposit),
        },
      },
    })
    if (errors?.length) {
      setLoading(false)
      const errMessage = commonHelper.parseGraphqlErrorMessage(errors)
      message.error(errMessage)
      return
    }
    message.success('Operation finished successfully')
  }

  const { handleSaveAndGoBack, handleSave, handleSaveAndContinue } = useGeneral({ handleSubmit, setLoading })

  const [saveType, setSaveType] = useState<'save' | 'saveAndGoBack' | 'saveAndContinue'>('save')

  const onSubmit = () => {
    if (saveType === 'saveAndGoBack') {
      handleSaveAndGoBack()
    } else if (saveType === 'saveAndContinue') {
      handleSaveAndContinue()
    } else if (saveType === 'save') {
      handleSave()
    }
  }

  const onFinishFailed = (errorInfo: any) => {
    message.error('please fill out every required field')
  }

  const handleAddButton = () => {
    eventManager.openEditAssetModal(
      {},
      {
        confirm: () => {},
      },
    )
  }

  let assetsTotalValue = 0

  const assetsDataSource =
    otherAssets.map((asset: any) => {
      assetsTotalValue += Number(asset.assetValue)
      return {
        id: asset._id,
        assetOwner: customersOptions.find((customer: any) => customer.value === asset.assetOwner)?.label,
        assetType: asset.assetType === 'other' ? asset.description : asset.assetType,
        assetValue: asset.assetValue,
      }
    }) || []

  const [selected, setSelected] = useState<TypeRowSelection>({})
  const displayDeleteButton = !!Object.keys(selected as object).length
  const displayEditButton = Object.keys(selected as object).length === 1

  const setSelectedRows = (selectedRowObj: TypeRowSelection) => {
    setSelected(selectedRowObj)
  }

  const handleDelete = async () => {
    setLoading(true)
    const otherAssets_IdToDelete = Object.keys(selected as object)
    const { errors } = await deleteOtherAssets({ _ids: otherAssets_IdToDelete, idNumber })
    if (errors?.length) {
      setLoading(false)
      const errMessage = commonHelper.parseGraphqlErrorMessage(errors)
      message.error(errMessage)
      return
    }
    message.success('Deleted successfully')
    setLoading(false)
    refetch()
    setSelected({})
  }

  const handleDeleteModal = () => {
    eventManager.openConfirmDeleteModal(
      { header: 'Do you really want to delete? This process can’t be undone.' },
      {
        confirm: () => handleDelete(),
      },
    )
  }

  const handleEditModal = () => {
    eventManager.openEditAssetModal(
      { _id: Object.keys(selected as object)[0], isEdit: true },
      {
        confirm: () => {},
      },
    )
  }

  return {
    onFinishFailed,
    onSubmit,
    setSaveType,
    loading,
    formData,
    handleAntdFormItemChange,
    handleAddButton,
    assetsDataSource,
    assetsTotalValue,
    setSelectedRows,
    displayDeleteButton,
    handleDeleteModal,
    displayEditButton,
    handleEditModal,
  }
}

export default useAssets
