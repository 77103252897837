import { Form } from 'antd'

import Loading from 'components/Loading'

import { yesNoOptions } from 'utils/commonOptions'
import CustomFooter from 'pages/QuickSearch/CustomFooter/CustomFooter'

import { StyledContainer, StyledTitle, StyledTitleContainer, StyledRequiredMsg, StyledBreakLine } from './StrokeDetails.styles'
import CustomFormItem from 'components/CustomFormItem/CustomFormItem'
import useStrokeDetails from 'hooks/clients/QuickSearch/MedicalDetails/useStrokeDetails'

const StrokeDetails = () => {
  const {
    loading,
    handleNext,
    handleAntdFormItemChange,
    flattenedFormData,
    customerFirstName,
    customerSecondName,
    enterMedicalDetailsOfCustomerFirst,
    enterMedicalDetailsOfCustomerSecond,
  } = useStrokeDetails()

  if (loading) {
    return <Loading />
  }

  return (
    <StyledContainer>
      <Form
        layout="vertical"
        size="large"
        name="basic"
        onFinish={handleNext}
        initialValues={flattenedFormData}
        style={{
          display: 'flex',
          flexDirection: 'column',
          rowGap: '12px',
          marginTop: '8px',
          width: '100%',
        }}
      >
        {enterMedicalDetailsOfCustomerFirst && (
          <>
            <StyledTitleContainer>
              <StyledTitle>Stroke Details Details of {customerFirstName}</StyledTitle>
              <StyledRequiredMsg>* For mandatory information</StyledRequiredMsg>
            </StyledTitleContainer>
            <CustomFormItem
              label="Have you had a major stroke (CVA), excluding mini-strokes (TIAs)? *"
              onChange={handleAntdFormItemChange}
              options={yesNoOptions}
              name="customerFirst.hadMajorStroke"
              type="radio"
              rules={['isRequired']}
            />
            {flattenedFormData['customerFirst.hadMajorStroke'] && (
              <CustomFormItem
                label="Date of (first) occurrence (YYYY-MM) *"
                onChange={handleAntdFormItemChange}
                name="customerFirst.firstMajorStrokeOccurrenceDate"
                rules={['isRequired', 'iressDate']}
              />
            )}
            <CustomFormItem
              label="Have you had a mini stroke (TIA)? *"
              onChange={handleAntdFormItemChange}
              options={yesNoOptions}
              name="customerFirst.hadMiniStroke"
              type="radio"
              rules={['isRequired']}
            />
            {flattenedFormData['customerFirst.hadMiniStroke'] && (
              <>
                <CustomFormItem
                  label="Does this require prescribed medication? *"
                  onChange={handleAntdFormItemChange}
                  options={yesNoOptions}
                  name="customerFirst.miniStrokeRequiresPrescribedMedication"
                  type="radio"
                  rules={['isRequired']}
                />
                <CustomFormItem
                  label="Date of (first) occurrence (YYYY-MM) *"
                  onChange={handleAntdFormItemChange}
                  name="customerFirst.firstMiniStrokeOccurrenceDate"
                  rules={['isRequired', 'iressDate']}
                />
              </>
            )}
          </>
        )}
        {enterMedicalDetailsOfCustomerSecond && (
          <>
            {enterMedicalDetailsOfCustomerFirst && <StyledBreakLine />}
            <StyledTitleContainer>
              <StyledTitle>Stroke Details Details of {customerSecondName}</StyledTitle>
              <StyledRequiredMsg>* For mandatory information</StyledRequiredMsg>
            </StyledTitleContainer>
            <CustomFormItem
              label="Have you had a major stroke (CVA), excluding mini-strokes (TIAs)? *"
              onChange={handleAntdFormItemChange}
              options={yesNoOptions}
              name="customerSecond.hadMajorStroke"
              type="radio"
              rules={['isRequired']}
            />
            {flattenedFormData['customerSecond.hadMajorStroke'] && (
              <CustomFormItem
                label="Date of (first) occurrence (YYYY-MM) *"
                onChange={handleAntdFormItemChange}
                name="customerSecond.firstMajorStrokeOccurrenceDate"
                rules={['isRequired', 'iressDate']}
              />
            )}
            <CustomFormItem
              label="Have you had a mini stroke (TIA)? *"
              onChange={handleAntdFormItemChange}
              options={yesNoOptions}
              name="customerSecond.hadMiniStroke"
              type="radio"
              rules={['isRequired']}
            />
            {flattenedFormData['customerSecond.hadMiniStroke'] && (
              <>
                <CustomFormItem
                  label="Does this require prescribed medication? *"
                  onChange={handleAntdFormItemChange}
                  options={yesNoOptions}
                  name="customerSecond.miniStrokeRequiresPrescribedMedication"
                  type="radio"
                  rules={['isRequired']}
                />
                <CustomFormItem
                  label="Date of (first) occurrence (YYYY-MM) *"
                  onChange={handleAntdFormItemChange}
                  name="customerSecond.firstMiniStrokeOccurrenceDate"
                  rules={['isRequired', 'iressDate']}
                />
              </>
            )}
          </>
        )}
        <CustomFooter loading={loading} buttonText="Next" buttonTheme="light" />
      </Form>
    </StyledContainer>
  )
}

export default StrokeDetails
