import { message } from 'antd'
import { commonHelper } from 'helpers'
import { useCallback, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { TypeRowSelection } from '@inovua/reactdatagrid-community/types'

import { useGetClients } from 'services/clients/clients.service'
import { commonUtils, eventManager } from 'utils'

import { clientsColumns } from './staticData'
import { deleteClientsMutation } from 'gql/client'
import { useCustomMutation } from 'services/shared/mutation'
import { IdNumbersPayload } from 'services/shared/mutation.payload'
import debounce from 'utils/debounce'

const useClientsPage = () => {
  const deleteClients = useCustomMutation<IdNumbersPayload>({ mutation: deleteClientsMutation })
  const navigate = useNavigate()
  const limit = 10
  const { clients, refetch, count } = useGetClients({
    skip: 0,
    limit,
  })
  const [loading, setLoading] = useState(false)
  const [selected, setSelected] = useState<TypeRowSelection>({})
  const [searchValue, setSearchValue] = useState('')
  const [displayDeleteButton, setDisplayDeleteButton] = useState(!!Object.keys(selected as object).length)

  const setSelectedRows = (selectedRowObj: TypeRowSelection) => {
    setSelected(selectedRowObj)
    setDisplayDeleteButton(!!Object.keys(selectedRowObj as object).length)
  }

  const clientsDataSource = clients.map((client: any) => {
    let name = ''
    if (client?.customerFirst?.firstName) name += client?.customerFirst?.firstName
    if (client?.customerFirst?.lastName) name += ` ${client?.customerFirst?.lastName}`
    if (client?.customerSecond?.firstName) name += ` / ${client?.customerSecond?.firstName}`
    if (client?.customerSecond?.lastName) name += ` ${client?.customerSecond?.lastName}`

    let number = ''

    if (client?.customerFirst?.landlineTelephone) number = client?.customerFirst?.landlineTelephone
    if (client?.customerFirst?.mobileTelephone) number = client?.customerFirst?.mobileTelephone

    let postcode = client.properties?.[0]?.address?.postcode || ''
    let value = client.properties?.[0]?.propertyValue || ''

    const formattedDate = new Date(Number(client.createdAt)).toLocaleDateString('en-GB')

    return {
      id: client.idNumber,
      createdAt: formattedDate,
      completedPercentage: client?.completedPercentage,
      name: name || '',
      phoneNumber: number,
      postcode,
      lender: client?.tracking?.lender,
      amount: client?.tracking?.amount,
      value,
      applicationDate: client?.tracking?.applicationDate,
      valuation: client?.tracking?.valuation,
      offerDate: client?.tracking?.offer,
      solicitor: client?.tracking?.solicitor,
      feeAuthoSentDate: client?.tracking?.feeAuthoSentDate,
    }
  })

  const handleDelete = async () => {
    setLoading(true)
    const clientsIdNumbersToDelete = Object.keys(selected as object)
    const { errors } = await deleteClients({ idNumbers: clientsIdNumbersToDelete })
    if (errors?.length) {
      setLoading(false)
      const errMessage = commonHelper.parseGraphqlErrorMessage(errors)
      message.error(errMessage)
      return
    }
    message.success('Deleted successfully')
    setLoading(false)
    refetch()
    setDisplayDeleteButton(false)
  }

  const handleDeleteModal = () => {
    eventManager.openConfirmDeleteModal(
      { header: 'Do you really want to delete? This process can’t be undone.' },
      {
        confirm: () => handleDelete(),
      },
    )
  }

  const onRowClick = useCallback((rowProps: any, event: any) => {
    const idNumber = rowProps.data.id
    navigate(`/client-details?idNumber=${idNumber}`)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const handleQuickSearch = () => {
    localStorage.removeItem('quickSearch')
    navigate(`/quick-search`)
  }

  const addNewClient = () => {
    const idNumber = commonUtils.generateIdNumber()
    navigate(`/client-details?idNumber=${idNumber}`)
  }

  const debouncedRefetch = debounce((newSearchValue: string) => {
    refetch({
      searchValue: newSearchValue,
    })
  })

  const handleAntdFormItemChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchValue(e.target.value)
    debouncedRefetch(e.target.value)
  }

  return {
    refetch,
    limit,
    count,
    clientsColumns,
    clientsDataSource,
    selected,
    setSelectedRows,
    displayDeleteButton,
    handleDeleteModal,
    loading,
    onRowClick,
    handleQuickSearch,
    addNewClient,
    handleAntdFormItemChange,
    searchValue,
  }
}

export default useClientsPage
