import { useCallback, useContext, useEffect, useState } from 'react'
import Form from 'antd/lib/form'
import message from 'antd/lib/message'
import { Tooltip } from '@mui/material'
import VerifiedOutlinedIcon from '@mui/icons-material/VerifiedOutlined'
import AddCircleOutlinedIcon from '@mui/icons-material/AddCircleOutlined'
import { TypeRowSelection } from '@inovua/reactdatagrid-community/types'

import { commonHelper } from 'helpers'
import AuthContext from 'contexts/AuthContext'
import { useGetUsers } from 'services/auth/auth.service'
import { useGetCompanies } from 'services/company/company-service'
import { eventManager } from 'utils'
import { useCustomMutation } from 'services/shared/mutation'
import { AddUserPayload, EditUserPayload, TIdsPayload, _idPayload } from 'services/shared/mutation.payload'
import {
  addUserMutation,
  deleteUsersMutation,
  editUserMutation,
  generateSuadaUserMutation,
  resendWelcomeEmailMutation,
  resetPasswordMutation,
  suspendUsersMutation,
} from 'gql/user'
import { useNavigate } from 'react-router-dom'
import debounce from 'utils/debounce'
import { ResetIcon, SendIcon } from 'assets/svg'

const generateUsersColumns = ({ handleResetPasswordModal, handleResendWelcomeEmailModal, generateSuadaUserClick }: any) => {
  return [
    {
      name: 'firstName',
      header: 'First Name',
      defaultFlex: 3,
      type: 'text',
    },
    {
      name: 'lastName',
      header: 'Last Name',
      defaultFlex: 3,
      type: 'text',
    },
    {
      name: 'email',
      header: 'Email',
      defaultFlex: 3,
      type: 'text',
    },
    {
      name: 'phoneNumber',
      header: 'Phone Number',
      defaultFlex: 3,
      type: 'text',
    },
    {
      name: 'company',
      header: 'Company',
      defaultFlex: 3,
      type: 'text',
    },
    {
      name: 'actions',
      header: 'Actions',
      defaultFlex: 1,
      render: ({ data }: any) => {
        return (
          <div
            style={{
              display: 'flex',
              justifyContent: 'space-around',
              alignItems: 'center',
              width: '100%',
            }}
          >
            <Tooltip title={'Reset Password'} arrow PopperProps={{ style: { maxWidth: '200px', textAlign: 'center' } }}>
              <span
                style={{ cursor: 'pointer' }}
                onClick={(e: any) => {
                  handleResetPasswordModal(e, data.id)
                }}
              >
                <ResetIcon />
              </span>
            </Tooltip>
            <Tooltip title={'Resend Details'} arrow PopperProps={{ style: { maxWidth: '200px', textAlign: 'center' } }}>
              <span
                style={{ cursor: 'pointer' }}
                onClick={(e: any) => {
                  handleResendWelcomeEmailModal(e, data.id)
                }}
              >
                <SendIcon />
              </span>
            </Tooltip>
            {data.hasSuadaUser ? (
              <Tooltip title={'Has Suada User'} arrow PopperProps={{ style: { maxWidth: '200px', textAlign: 'center' } }}>
                <VerifiedOutlinedIcon
                  style={{
                    color: 'blue',
                  }}
                />
              </Tooltip>
            ) : (
              <Tooltip title={'Generate Suada User'} arrow PopperProps={{ style: { maxWidth: '200px', textAlign: 'center' } }}>
                <span
                  style={{ cursor: 'pointer' }}
                  onClick={(e: any) => {
                    generateSuadaUserClick(e, data.id)
                  }}
                >
                  <AddCircleOutlinedIcon />
                </span>
              </Tooltip>
            )}
          </div>
        )
      },
    },
  ]
}

const initialCompanyId = process.env.REACT_APP_STAGE?.includes('development')
  ? '65ae5ec58fb704c38f59e48d'
  : process.env.REACT_APP_STAGE?.includes('production')
  ? '65c0dbd4339558cb5a5ba6de'
  : ''

const useUsersPage = () => {
  const navigate = useNavigate()
  const [form] = Form.useForm()
  const { user } = useContext(AuthContext)
  const resetPassword = useCustomMutation<_idPayload>({ mutation: resetPasswordMutation })
  const generateSuadaUser = useCustomMutation<_idPayload>({ mutation: generateSuadaUserMutation })
  const resendWelcomeEmail = useCustomMutation<_idPayload>({ mutation: resendWelcomeEmailMutation })
  const addUser = useCustomMutation<AddUserPayload>({ mutation: addUserMutation })
  const suspendUsers = useCustomMutation<TIdsPayload>({ mutation: suspendUsersMutation })
  const editUser = useCustomMutation<EditUserPayload>({ mutation: editUserMutation })
  const deleteUsers = useCustomMutation<TIdsPayload>({ mutation: deleteUsersMutation })
  const [loading, setLoading] = useState(false)
  const [open, setOpen] = useState(false)
  const [drawerType, setDrawerType] = useState('add')
  const [searchValue, setSearchValue] = useState('')
  // Note: Company drawers's form fields
  const [formData, setFormData] = useState<any>({
    _id: '',
    firstName: '',
    lastName: '',
    email: '',
    phoneNumber: '',
    companyId: initialCompanyId,
    iressLoginId: '',
    iressPassword: '',
    iressUserId: '',
    fcaNumber: '',
  })

  // Note: user will be companies or not
  const [companyUser, setCompanyUser] = useState(false)
  const [companyId, setCompanyId] = useState(initialCompanyId)
  const { companies } = useGetCompanies()
  const limit = 10
  const { users, refetch, count } = useGetUsers({
    skip: 0,
    limit,
  })
  // Note: users drawer company options, which company's user it is
  const [options, setOptions] = useState([])

  const [selected, setSelected] = useState<TypeRowSelection>({})
  // Note: works in delete button too
  const displaySuspendButton = !!Object.keys(selected as object).length
  const displayEditButton = Object.keys(selected as object).length === 1

  const handleResetPassword = async (_id: string) => {
    const { errors } = await resetPassword({ _id })
    if (errors?.length) {
      setLoading(false)
      const errMessage = commonHelper.parseGraphqlErrorMessage(errors)
      message.error(errMessage)
      return
    }
    message.success('Password reset successfully')
  }

  const handleResendWelcomeEmail = async (_id: string) => {
    const { errors } = await resendWelcomeEmail({ _id })
    if (errors?.length) {
      setLoading(false)
      const errMessage = commonHelper.parseGraphqlErrorMessage(errors)
      message.error(errMessage)
      return
    }
    message.success('Email sent successfully')
  }

  const handleResendWelcomeEmailModal = (e: any, id: string) => {
    e.stopPropagation()
    eventManager.openConfirmModal(
      { header: 'Do you want to resend welcome email?' },
      {
        confirm: () => handleResendWelcomeEmail(id),
      },
    )
  }

  const handleResetPasswordModal = (e: any, id: string) => {
    e.stopPropagation()
    eventManager.openConfirmModal(
      { header: 'Do you want to reset password?' },
      {
        confirm: () => handleResetPassword(id),
      },
    )
  }

  const handleGenerateSuadaUser = async (_id: string) => {
    const { errors } = await generateSuadaUser({ _id })
    if (errors?.length) {
      setLoading(false)
      const errMessage = commonHelper.parseGraphqlErrorMessage(errors)
      message.error(errMessage)
      return
    }
    refetch()
    message.success('User generated successfully')
  }

  const generateSuadaUserClick = (e: any, id: string) => {
    e.stopPropagation()
    eventManager.openConfirmModal(
      { header: 'Do you want to generate Suada user?' },
      {
        confirm: () => handleGenerateSuadaUser(id),
      },
    )
  }

  const usersColumns = generateUsersColumns({ handleResetPasswordModal, handleResendWelcomeEmailModal, generateSuadaUserClick })

  const setSelectedRows = (selectedRowObj: TypeRowSelection) => {
    setSelected(selectedRowObj)
  }

  const handleSuspend = async () => {
    setLoading(true)
    const usersIdsToSuspend = Object.keys(selected as object)
    const { errors } = await suspendUsers({ ids: usersIdsToSuspend })
    if (errors?.length) {
      setLoading(false)
      const errMessage = commonHelper.parseGraphqlErrorMessage(errors)
      message.error(errMessage)
      return
    }
    message.success('Suspended successfully')
    setLoading(false)
    refetch()
  }

  const handleDelete = async () => {
    setLoading(true)
    const usersIdsToDelete = Object.keys(selected as object)
    const { errors } = await deleteUsers({ ids: usersIdsToDelete })
    if (errors?.length) {
      setLoading(false)
      const errMessage = commonHelper.parseGraphqlErrorMessage(errors)
      message.error(errMessage)
      return
    }
    message.success('Deleted successfully')
    setLoading(false)
    refetch()
  }

  const handleDeleteModal = () => {
    eventManager.openConfirmDeleteModal(
      { header: 'Do you really want to delete? This process can’t be undone.' },
      {
        confirm: () => handleDelete(),
      },
    )
  }

  useEffect(() => {
    if (user.role === 'superAdmin') {
      setOptions(
        companies.map((company: any) => ({
          value: company._id,
          label: company.name,
        })),
      )
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [companies])

  const handleCustomSelectChange = (name: string, value: string) => {
    refetch({
      companyId: value,
    })
  }

  const usersDataSource = users.map((user: any) => {
    const companyName = companies.find((company: any) => company._id === user.companyId)?.name
    return {
      id: user._id,
      firstName: user.firstName,
      lastName: user.lastName,
      email: user.email,
      phoneNumber: user.phoneNumber,
      companyId: user.companyId,
      company: companyName,
      iressLoginId: user.iressLoginId,
      iressPassword: user.iressPassword,
      iressUserId: user.iressUserId,
      fcaNumber: user.fcaNumber,
      hasSuadaUser: user.hasSuadaUser,
    }
  })

  const handleCompanyChange = (name: string, value: string) => {
    setCompanyId(value)
  }

  const clearDrawerData = () => {
    setFormData({
      firstName: '',
      lastName: '',
      email: '',
      phoneNumber: '',
      iressLoginId: '',
      iressPassword: '',
      iressUserId: '',
      fcaNumber: '',
    })
    form.setFieldsValue({
      firstName: '',
      lastName: '',
      email: '',
      phoneNumber: '',
      iressLoginId: '',
      iressPassword: '',
      iressUserId: '',
      fcaNumber: '',
    })
  }

  const handleEdit = () => {
    setDrawerType('edit')

    const userToEditId = Object.keys(selected as object)[0]
    const userToEdit = usersDataSource.find((user: any) => user.id === userToEditId)
    setFormData({
      _id: userToEdit.id,
      firstName: userToEdit.firstName,
      lastName: userToEdit.lastName,
      email: userToEdit.email,
      phoneNumber: userToEdit.phoneNumber,
      companyId: userToEdit.companyId,
      iressLoginId: userToEdit.iressLoginId,
      iressPassword: userToEdit.iressPassword,
      iressUserId: userToEdit.iressUserId,
      fcaNumber: userToEdit.fcaNumber,
    })
    form.setFieldsValue({
      _id: userToEdit.id,
      firstName: userToEdit.firstName,
      lastName: userToEdit.lastName,
      email: userToEdit.email,
      phoneNumber: userToEdit.phoneNumber,
      iressLoginId: userToEdit.iressLoginId,
      iressPassword: userToEdit.iressPassword,
      iressUserId: userToEdit.iressUserId,
      fcaNumber: userToEdit.fcaNumber,
    })
    setCompanyId(userToEdit.companyId)
    setCompanyUser(!!userToEdit.companyId)
    setOpen(true)
  }

  const companyUserChange = (checked: boolean) => {
    setCompanyUser(checked)
  }

  const handleAddUser = () => {
    setDrawerType('add')
    setOpen(true)
  }

  const closeDrawer = () => {
    setOpen(false)
    setDrawerType('add')
    clearDrawerData()
  }

  const handleSubmit = async () => {
    setLoading(true)

    const { _id, ...rest } = formData

    const isEveryFiledFilled = ['firstName', 'lastName', ...(drawerType === 'add' ? ['email'] : []), 'phoneNumber'].every(
      (fieldKey: string) => !!rest[fieldKey] !== false,
    )
    if (!isEveryFiledFilled) {
      message.info('Please fill out all the fields')
      setLoading(false)
      return
    }

    const fcaNumber = rest.fcaNumber
    if (!!fcaNumber && !(fcaNumber?.length === 6 || fcaNumber?.length === 7)) {
      message.info('Please check FCA number, it should be 6 or 7 digits')
      setLoading(false)
      return
    }

    if (drawerType === 'add') {
      const { errors } = await addUser({ ...rest, companyId, iressUserId: Number(rest.iressUserId), fcaNumber: Number(rest.fcaNumber) })
      if (errors?.length) {
        setLoading(false)
        const errMessage = commonHelper.parseGraphqlErrorMessage(errors)
        message.error(errMessage)
        return
      }
      message.success('New user created successfully')
    } else if (drawerType === 'edit') {
      const { errors } = await editUser({
        ...rest,
        _id,
        companyId,
        iressUserId: Number(rest.iressUserId),
        fcaNumber: Number(rest.fcaNumber),
      })
      if (errors?.length) {
        setLoading(false)
        const errMessage = commonHelper.parseGraphqlErrorMessage(errors)
        message.error(errMessage)
        return
      }
      message.success('user edited successfully')
    }
    setLoading(false)
    refetch()
    clearDrawerData()
    setOpen(false)
  }

  const handleFormFieldChange = (e: any) => {
    setFormData((prevState: any) => ({
      ...prevState,
      [e.target.name]: e.target.value,
    }))
  }

  const onRowClick = useCallback((rowProps: any, event: any) => {
    const user_id = rowProps.data.id
    navigate(`/user-profile/${user_id}`)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const debouncedRefetch = debounce((newSearchValue: string) => {
    refetch({
      searchValue: newSearchValue,
    })
  })

  const handleAntdFormItemChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchValue(e.target.value)
    debouncedRefetch(e.target.value)
  }

  return {
    form,
    handleCustomSelectChange,
    usersColumns,
    usersDataSource,
    refetch,
    options,
    count,
    limit,
    handleAddUser,
    closeDrawer,
    formData,
    open,
    loading,
    handleSubmit,
    handleFormFieldChange,
    companyUserChange,
    companyUser,
    handleCompanyChange,
    drawerType,
    handleEdit,
    displaySuspendButton,
    displayEditButton,
    setSelectedRows,
    handleSuspend,
    handleDeleteModal,
    user,
    onRowClick,
    handleAntdFormItemChange,
    searchValue,
  }
}

export default useUsersPage
