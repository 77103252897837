import { loader } from 'graphql.macro'

// Note: Mutations
export const addEditClientTrackingMutation = loader('./addEditClientTrackingMutation.graphql')
export const addEditClientJourneyMutation = loader('./addEditClientJourneyMutation.graphql')
export const deleteClientsMutation = loader('./deleteClientsMutation.graphql')
export const deleteClientFromStatsMutation = loader('./deleteClientFromStatsMutation.graphql')
export const deleteOtherAssetsMutation = loader('./deleteOtherAssetsMutation.graphql')
export const deleteOtherLiabilitiesMutation = loader('./deleteOtherLiabilitiesMutation.graphql')
export const addEditFormMutation = loader('./addEditFormMutation.graphql')
export const addEditPersonPresentDuringFactFindMutation = loader('./addEditPersonPresentDuringFactFindMutation.graphql')
export const deletePersonPresentDuringFactFindMutation = loader('./deletePersonPresentDuringFactFindMutation.graphql')
export const addEditPreviousAddressHistoryMutation = loader('./addEditPreviousAddressHistoryMutation.graphql')
export const deletePreviousAddressHistoryMutation = loader('./deletePreviousAddressHistoryMutation.graphql')
export const addEditOccupantMutation = loader('./addEditOccupantMutation.graphql')
export const deleteOccupantsMutation = loader('./deleteOccupantsMutation.graphql')
export const addEditOtherResidentMutation = loader('./addEditOtherResidentMutation.graphql')
export const deleteOtherResidentsMutation = loader('./deleteOtherResidentsMutation.graphql')
export const addPropertyMutation = loader('./addPropertyMutation.graphql')
export const removePropertyMutation = loader('./removePropertyMutation.graphql')
export const addEditOtherLiabilitiesMutation = loader('./addEditOtherLiabilitiesMutation.graphql')
export const addEditOtherAssetsMutation = loader('./addEditOtherAssetsMutation.graphql')
export const addEditIncomeSourceMutation = loader('./addEditIncomeSourceMutation.graphql')
export const deleteIncomeSourcesMutation = loader('./deleteIncomeSourcesMutation.graphql')
export const addEditFuturePensionMutation = loader('./addEditFuturePensionMutation.graphql')
export const deleteFuturePensionsMutation = loader('./deleteFuturePensionsMutation.graphql')
export const addEditBenefitReceivingMutation = loader('./addEditBenefitReceivingMutation.graphql')
export const deleteBenefitsReceivingMutation = loader('./deleteBenefitsReceivingMutation.graphql')
export const addEditEligibleBenefitMutation = loader('./addEditEligibleBenefitMutation.graphql')
export const deleteEligibleBenefitsMutation = loader('./deleteEligibleBenefitsMutation.graphql')
export const addEditExpenditureDetailsMutation = loader('./addEditExpenditureDetailsMutation.graphql')
export const deleteExpendituresDetailsMutation = loader('./deleteExpendituresDetailsMutation.graphql')
export const addEditObjectiveMutation = loader('./addEditObjectiveMutation.graphql')
export const deleteObjectivesMutation = loader('./deleteObjectivesMutation.graphql')
export const addEditBeneficiaryMutation = loader('./addEditBeneficiaryMutation.graphql')
export const deleteBeneficiariesMutation = loader('./deleteBeneficiariesMutation.graphql')
export const addEditDependentMutation = loader('./addEditDependentMutation.graphql')
export const deleteDependentsMutation = loader('./deleteDependentsMutation.graphql')
export const addEditRecipientMutation = loader('./addEditRecipientMutation.graphql')
export const deleteRecipientsMutation = loader('./deleteRecipientsMutation.graphql')
export const addQuoteDetailsMutation = loader('./addQuoteDetailsMutation.graphql')

export const deleteFilesMutation = loader('./deleteFilesMutation.graphql')
export const saveNoteMutation = loader('./saveNoteMutation.graphql')
export const deleteNotesMutation = loader('./deleteNotesMutation.graphql')

// Note: Queries
export const getClientsPagination = loader('./getClientsPagination.graphql')
export const getClientsStatisticsPagination = loader('./getClientsStatisticsPagination.graphql')
export const getClientsForLinkingInStats = loader('./getClientsForLinkingInStats.graphql')
export const caseOverviewForWarningsQuery = loader('./caseOverviewForWarningsQuery.graphql')
export const caseOverviewInfoQuery = loader('./caseOverviewInfoQuery.graphql')
export const personsPresentDuringFactFindQuery = loader('./personsPresentDuringFactFindQuery.graphql')
export const getClientCountQuery = loader('./getClientCountQuery.graphql')
export const getGetClientAgentQuery = loader('./getGetClientAgentQuery.graphql')
export const getCustomerFirstInfoQuery = loader('./getCustomerFirstInfoQuery.graphql')
export const getCustomerSecondInfoQuery = loader('./getCustomerSecondInfoQuery.graphql')
export const getCustomersQuery = loader('./getCustomersQuery.graphql')
export const propertyInfoQuery = loader('./propertyInfoQuery.graphql')
export const previousAddressesQuery = loader('./previousAddressesQuery.graphql')
export const occupantsQuery = loader('./occupantsQuery.graphql')
export const otherResidentsQuery = loader('./otherResidentsQuery.graphql')
export const propertiesInfoQuery = loader('./propertiesInfoQuery.graphql')
export const propertiesInfoForWarningsQuery = loader('./propertiesInfoForWarningsQuery.graphql')
export const liabilitiesInfoQuery = loader('./liabilitiesInfoQuery.graphql')
export const otherLiabilitiesQuery = loader('./otherLiabilitiesQuery.graphql')
export const assetsInfoQuery = loader('./assetsInfoQuery.graphql')
export const otherAssetsQuery = loader('./otherAssetsQuery.graphql')
export const creditHistoryInfoQuery = loader('./creditHistoryInfoQuery.graphql')
export const incomeInfoQuery = loader('./incomeInfoQuery.graphql')
export const incomeSourcesQuery = loader('./incomeSourcesQuery.graphql')
export const futurePensionsQuery = loader('./futurePensionsQuery.graphql')
export const benefitsInfoQuery = loader('./benefitsInfoQuery.graphql')
export const benefitsReceivingQuery = loader('./benefitsReceivingQuery.graphql')
export const eligibleBenefitsQuery = loader('./eligibleBenefitsQuery.graphql')
export const expenditureInfoQuery = loader('./expenditureInfoQuery.graphql')
export const expendituresDetailsQuery = loader('./expendituresDetailsQuery.graphql')
export const objectivesAndAlternativesInfoQuery = loader('./objectivesAndAlternativesInfoQuery.graphql')
export const objectivesQuery = loader('./objectivesQuery.graphql')
export const familyInfoQuery = loader('./familyInfoQuery.graphql')
export const beneficiariesQuery = loader('./beneficiariesQuery.graphql')
export const dependentsQuery = loader('./dependentsQuery.graphql')
export const recipientsQuery = loader('./recipientsQuery.graphql')
export const healthInfoQuery = loader('./healthInfoQuery.graphql')
export const borrowingSolutionAndPrioritiesInfoQuery = loader('./borrowingSolutionAndPrioritiesInfoQuery.graphql')
export const estatePlanningInfoQuery = loader('./estatePlanningInfoQuery.graphql')
export const suitabilityAndNextStepsInfoQuery = loader('./suitabilityAndNextStepsInfoQuery.graphql')
export const getClientFormsInfoQuery = loader('./getClientFormsInfoQuery.graphql')
export const getRecommendedProductQuery = loader('./getRecommendedProductQuery.graphql')

export const clientDataForTrackingQuery = loader('./clientDataForTrackingQuery.graphql')
export const clientJourneyQuery = loader('./clientJourneyQuery.graphql')
export const clientFormsProgressQuery = loader('./clientFormsProgressQuery.graphql')
export const liabilityQuery = loader('./liabilityQuery.graphql')
export const beneficiaryQuery = loader('./beneficiaryQuery.graphql')
export const dependentQuery = loader('./dependentQuery.graphql')
export const recipientQuery = loader('./recipientQuery.graphql')
export const receivingBenefitQuery = loader('./receivingBenefitQuery.graphql')
export const eligibleBenefitQuery = loader('./eligibleBenefitQuery.graphql')
export const otherAssetQuery = loader('./otherAssetQuery.graphql')
export const expenditureDetailsQuery = loader('./expenditureDetailsQuery.graphql')
export const incomeSourceQuery = loader('./incomeSourceQuery.graphql')
export const futurePensionQuery = loader('./futurePensionQuery.graphql')
export const objectiveQuery = loader('./objectiveQuery.graphql')
export const previousAddressQuery = loader('./previousAddressQuery.graphql')
export const occupantQuery = loader('./occupantQuery.graphql')
export const otherResidentQuery = loader('./otherResidentQuery.graphql')
export const personPresentDuringFactFindQuery = loader('./personPresentDuringFactFindQuery.graphql')
export const getQuoteHistoryQuery = loader('./getQuoteHistoryQuery.graphql')
export const getQuickSearchDataForClientDetailsQuery = loader('./getQuickSearchDataForClientDetailsQuery.graphql')
export const getQuickSearchDataForPropertyAndLandingQuery = loader('./getQuickSearchDataForPropertyAndLandingQuery.graphql')
export const getQuickSearchDataForFurtherInformationQuery = loader('./getQuickSearchDataForFurtherInformationQuery.graphql')

export const getClientFilesQuery = loader('./getClientFilesQuery.graphql')
export const getClientNotesQuery = loader('./getClientNotesQuery.graphql')

export const saveRecommendedProductMutation = loader('./saveRecommendedProductMutation.graphql')

export const linkClientMutation = loader('./linkClientMutation.graphql')
