export enum FORMS_ENUM {
  caseOverview = 'caseOverview',
  customerDetails = 'customerDetails',
  propertyDetails = 'propertyDetails',
  assets = 'assets',
  liabilities = 'liabilities',
  creditHistory = 'creditHistory',
  income = 'income',
  customerSecondIncome = 'customerSecondIncome',
  benefits = 'benefits',
  expenditure = 'expenditure',
  objectivesAndAlternatives = 'objectivesAndAlternatives',
  family = 'family',
  health = 'health',
  borrowingSolutionAndPriorities = 'borrowingSolutionAndPriorities',
  estatePlanning = 'estatePlanning',
  suitabilityAndNextSteps = 'suitabilityAndNextSteps',
}

export type TFormName =
  | 'caseOverview'
  | 'customerDetails'
  | 'propertyDetails'
  | 'assets'
  | 'liabilities'
  | 'creditHistory'
  | 'income'
  | 'customerSecondIncome'
  | 'benefits'
  | 'expenditure'
  | 'objectivesAndAlternatives'
  | 'family'
  | 'health'
  | 'borrowingSolutionAndPriorities'
  | 'estatePlanning'
  | 'suitabilityAndNextSteps'
