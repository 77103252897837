import { useGetQuoteHistory } from 'services/clients/clients.service'
import { quoteHistoryColumns } from './staticData'

interface IQuoteHistory {
  _id: string
  transactionNumber: number
  createdAt: string
  ownerIdNumber: number
}

const useQuoteHistoryTab = () => {
  const urlParams = new URLSearchParams(window.location.search)
  const idNumber = Number(urlParams.get('idNumber'))
  const { data, loading } = useGetQuoteHistory(idNumber)

  const quoteHistoryDataSource: any = data.map((item: IQuoteHistory) => ({
    id: item._id,
    name: item.createdAt,
    option: item._id,
    transactionNumber: item.transactionNumber,
  }))
  return { quoteHistoryColumns, quoteHistoryDataSource, loading }
}

export default useQuoteHistoryTab
