import { Progress } from 'antd'
import { IStyledPercentage } from './StyledPercentage.interface'

const StyledPercentage = ({ percentage, width = 48 }: IStyledPercentage) => {
  const calcColor = () => {
    if (percentage === 100) {
      return '#219653'
    } else if (percentage < 100 && percentage > 50) {
      return '#2080F6'
    } else if (percentage === 50) {
      return '#F4A118'
    } else if (percentage < 50) {
      return '#E73F3F'
    }
  }
  const color = calcColor()

  return <Progress width={width} type="circle" percent={percentage} strokeColor={color} />
}

export default StyledPercentage
