import { useEffect, useState } from 'react'
import { message } from 'antd'

import { commonHelper } from 'helpers'
import { commonUtils, eventManager } from 'utils'

import useGeneral from './useGeneral'
import { useGetBenefitsInfo, useGetBenefitsReceiving, useGetCustomers, useGetEligibleBenefits } from 'services/clients/clients.service'
import { benefitTypeOptions } from 'modals/BenefitReceivingModal/BenefitReceivingModal.helper'
import { addEditFormMutation, deleteBenefitsReceivingMutation, deleteEligibleBenefitsMutation } from 'gql/client'
import { useCustomMutation } from 'services/shared/mutation'
import { TAddEditFormMutationPayload, DeleteWithIdNumberAnd_IdsPayload } from 'services/shared/mutation.payload'
import { getCustomersOptions } from 'utils/getCustomersOptions'
import { periodOptions } from 'utils/commonOptions'
import { ILabelAndValue } from 'interfaces/common/labelAndValue'
import { FORMS_ENUM } from 'enum/form.enum'

const useBenefits = () => {
  const deleteBenefitsReceiving = useCustomMutation<DeleteWithIdNumberAnd_IdsPayload>({ mutation: deleteBenefitsReceivingMutation })
  const deleteEligibleBenefits = useCustomMutation<DeleteWithIdNumberAnd_IdsPayload>({ mutation: deleteEligibleBenefitsMutation })
  const addEditBenefits = useCustomMutation<TAddEditFormMutationPayload>({ mutation: addEditFormMutation, refetchQueries: ['getClientFormsProgress'] })
  const urlParams = new URLSearchParams(window.location.search)
  const idNumber = Number(urlParams.get('idNumber'))
  const { data, loading: formInitialDataLoading, refetch } = useGetBenefitsInfo(idNumber)
  const { benefitsReceiving } = useGetBenefitsReceiving(idNumber)
  const { eligibleBenefits } = useGetEligibleBenefits(idNumber)
  const [loading, setLoading] = useState(false)
  const [formData, setFormData] = useState({
    isReceivingAnyStateBenefits: data?.isReceivingAnyStateBenefits,
    hasCarriedOutStateBenefitsCheck: data?.hasCarriedOutStateBenefitsCheck,
    issuesCustomerBenefits: data?.issuesCustomerBenefits,
    givenCustomerGuidance: data?.givenCustomerGuidance,
  })
  const { data: customersData } = useGetCustomers(idNumber)
  const customersOptions: ILabelAndValue[] = getCustomersOptions(customersData, true)

  useEffect(() => {
    window.scroll({ top: 0, behavior: 'smooth' })
  }, [loading])

  useEffect(() => {
    setLoading(formInitialDataLoading)
    const newData = commonUtils.removeTypeNameFromObject(data)
    delete newData._id
    setFormData({ ...newData })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formInitialDataLoading])

  const handleAntdFormItemChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const parsedValue = commonUtils.parseValue(e.target?.value, e.target?.name)
    setFormData((prevState) => ({
      ...prevState,
      [e.target.name]: parsedValue,
    }))
  }

  const handleSubmit = async () => {
    const { errors } = await addEditBenefits({
      idNumber,
      formName: FORMS_ENUM.benefits,
      values: { benefits: formData },
    })
    if (errors?.length) {
      setLoading(false)
      const errMessage = commonHelper.parseGraphqlErrorMessage(errors)
      message.error(errMessage)
      return
    }
    message.success('Operation finished successfully')
  }

  const { handleSaveAndGoBack, handleSave, handleSaveAndContinue } = useGeneral({ handleSubmit, setLoading })

  const [saveType, setSaveType] = useState<'save' | 'saveAndGoBack' | 'saveAndContinue'>('save')

  const onSubmit = () => {
    if (saveType === 'saveAndGoBack') {
      handleSaveAndGoBack()
    } else if (saveType === 'saveAndContinue') {
      handleSaveAndContinue()
    } else if (saveType === 'save') {
      handleSave()
    }
  }

  const onFinishFailed = (errorInfo: any) => {
    message.error('please fill out every required field')
  }

  const handleAddBenefits = () => {
    eventManager.openBenefitReceivingModal(
      {},
      {
        confirm: () => {},
      },
    )
  }

  let benefitsTotalValue = 0

  const benefitsDataSource =
    benefitsReceiving.map((benefitReceiving: any) => {
      benefitsTotalValue += Number(benefitReceiving.benefitAmount)
      return {
        id: benefitReceiving._id,
        benefitReceiver: customersOptions.find((item: ILabelAndValue) => item.value === benefitReceiving.benefitReceiver)?.label,
        benefitType: benefitTypeOptions?.find((item: ILabelAndValue) => item.value === benefitReceiving.benefitType)?.label,
        benefitPeriod: periodOptions?.find((item: ILabelAndValue) => item.value === benefitReceiving.benefitPeriod)?.label,
        benefitAmount: benefitReceiving.benefitAmount,
        isMeansBenefit: benefitReceiving.isMeansBenefit,
      }
    }) || []

  const [selectedBenefits, setSelectedBenefits] = useState({})
  const displayDeleteBenefitsButton = !!Object.keys(selectedBenefits).length
  const displayBenefitEditButton = Object.keys(selectedBenefits).length === 1

  const setSelectedBenefitsRows = (selectedRowObj: any) => {
    setSelectedBenefits(selectedRowObj)
  }

  const handleDeleteBenefits = async () => {
    setLoading(true)
    const benefitsReceiving_IdToDelete = Object.keys(selectedBenefits)
    const { errors } = await deleteBenefitsReceiving({ _ids: benefitsReceiving_IdToDelete, idNumber })
    if (errors?.length) {
      setLoading(false)
      const errMessage = commonHelper.parseGraphqlErrorMessage(errors)
      message.error(errMessage)
      return
    }
    message.success('Deleted successfully')
    setLoading(false)
    refetch()
    setSelectedBenefits({})
  }

  const handleDeleteBenefitsModal = () => {
    eventManager.openConfirmDeleteModal(
      { header: 'Do you really want to delete? This process can’t be undone.' },
      {
        confirm: () => handleDeleteBenefits(),
      },
    )
  }

  const handleAddEligibleBenefit = () => {
    eventManager.openEligibleBenefitModal(
      {},
      {
        confirm: () => {},
      },
    )
  }

  let eligibleBenefitsTotalValue = 0

  const eligibleBenefitsDataSource =
    eligibleBenefits.map((benefitReceiving: any) => {
      eligibleBenefitsTotalValue += Number(benefitReceiving.benefitAmount)
      return {
        id: benefitReceiving._id,
        benefitReceiver: customersOptions.find((item: ILabelAndValue) => item.value === benefitReceiving.benefitReceiver)?.label,
        benefitType: benefitTypeOptions?.find((item: ILabelAndValue) => item.value === benefitReceiving.benefitType)?.label,
        benefitPeriod: periodOptions?.find((item: ILabelAndValue) => item.value === benefitReceiving.benefitPeriod)?.label,
        benefitAmount: benefitReceiving.benefitAmount,
      }
    }) || []

  const [selectedEligibleBenefits, setSelectedEligibleBenefits] = useState({})
  const displayDeleteEligibleBenefitsButton = !!Object.keys(selectedEligibleBenefits).length
  const displayEligibleBenefitEditButton = Object.keys(selectedEligibleBenefits).length === 1

  const setSelectedEligibleBenefitsRows = (selectedRowObj: any) => {
    setSelectedEligibleBenefits(selectedRowObj)
  }

  const handleDeleteEligibleBenefits = async () => {
    setLoading(true)
    const eligibleBenefits_IdToDelete = Object.keys(selectedEligibleBenefits)
    const { errors } = await deleteEligibleBenefits({ _ids: eligibleBenefits_IdToDelete, idNumber })
    if (errors?.length) {
      setLoading(false)
      const errMessage = commonHelper.parseGraphqlErrorMessage(errors)
      message.error(errMessage)
      return
    }
    message.success('Deleted successfully')
    setLoading(false)
    refetch()
    setSelectedEligibleBenefits({})
  }

  const handleDeleteEligibleBenefitsModal = () => {
    eventManager.openConfirmDeleteModal(
      { header: 'Do you really want to delete? This process can’t be undone.' },
      {
        confirm: () => handleDeleteEligibleBenefits(),
      },
    )
  }

  const handleBenefitEditModal = () => {
    eventManager.openBenefitReceivingModal(
      { _id: Object.keys(selectedBenefits)[0], isEdit: true },
      {
        confirm: () => {},
      },
    )
  }

  const handleEligibleBenefitEditModal = () => {
    eventManager.openEligibleBenefitModal(
      { _id: Object.keys(selectedEligibleBenefits)[0], isEdit: true },
      {
        confirm: () => {},
      },
    )
  }

  return {
    onFinishFailed,
    onSubmit,
    setSaveType,
    loading,
    formData,
    handleAntdFormItemChange,
    handleAddBenefits,
    benefitsDataSource,
    benefitsTotalValue,
    setSelectedBenefitsRows,
    displayDeleteBenefitsButton,
    handleDeleteBenefitsModal,
    handleAddEligibleBenefit,
    eligibleBenefitsDataSource,
    eligibleBenefitsTotalValue,
    setSelectedEligibleBenefitsRows,
    displayDeleteEligibleBenefitsButton,
    handleDeleteEligibleBenefitsModal,
    displayBenefitEditButton,
    handleBenefitEditModal,
    displayEligibleBenefitEditButton,
    handleEligibleBenefitEditModal,
  }
}

export default useBenefits
